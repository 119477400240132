import { useState } from 'react';

export function useCheckboxHook(initialItems: string[]) {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const isItemSelected = (id: string) => selectedItems?.includes(id);

  const toggleItemSelection = (id: string) => {
    if (isItemSelected(id)) {
      setSelectedItems(selectedItems.filter(item => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const selectAllItems = () => {
    setSelectedItems(initialItems.map(item => item));
  };

  const deselectAllItems = () => {
    setSelectedItems([]);
  };

  const areAllItemsSelected = initialItems.length === selectedItems.length;

  return {
    selectedItems,
    isItemSelected,
    toggleItemSelection,
    selectAllItems,
    deselectAllItems,
    areAllItemsSelected
  };
}