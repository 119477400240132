import styled from "styled-components";
import { BarChart } from "recharts";

export const StyledBarChart = styled(BarChart)`
margin-left: -10px;
& svg.recharts-surface {
    font-size: 1.2rem;   
}

& .recharts-legend-wrapper {
    font-size: 1.2rem;
    padding: 10px;

    & .recharts-legend-item {
        & svg {
            height: 2rem;
            width: 2rem;
        }

    }
}
`;