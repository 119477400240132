import { Reducer } from "redux";
import { PreAgendamentoRowResponse } from "../../../types/PreAgendamentos/preAgendamento";
import { PreAgendamentosState, PreAgendamentosStoreTypes } from "./types";

const INITIAL_STATE: PreAgendamentosState = {
    preAgendamentosList: [] as PreAgendamentoRowResponse[],
    loading: false
};

const reducer: Reducer<PreAgendamentosState> = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case PreAgendamentosStoreTypes.GET_PRE_AGENDAMENTOS:
        case PreAgendamentosStoreTypes.POST_PRE_AGENDAMENTO:
        case PreAgendamentosStoreTypes.POST_CONFIRMA_PRE_AGENDAMENTO:
        case PreAgendamentosStoreTypes.PUT_APROVACAO:
        case PreAgendamentosStoreTypes.PUT_HANDLE_DATE:
            return { ...state, loading: true };

        case PreAgendamentosStoreTypes.LOADING_SUCCESS:
        case PreAgendamentosStoreTypes.LOADING_FAILURE:
            return { ...state, ...action?.payload, loading: false };

        default:
            return state;
    }
};

export default reducer;
