import Pagination from 'react-bootstrap/Pagination';
import { renderPageNumbers } from '../service';
import { StyledDivPagination } from '../styles';

export default function CustomPagination(props: {
    currentPage: number;
    totalPaginas: number;
    changePageValue: (numeroDaPagina: number) => void;
}) {
    const {
        currentPage,
        totalPaginas,
        changePageValue,
    } = props;

    return (
        <>
            <StyledDivPagination>
                <Pagination>
                    <Pagination.First disabled={currentPage === 0} onClick={() => changePageValue(0)} id="first" />
                    <Pagination.Prev
                        onClick={() => changePageValue(currentPage - 1)}
                        disabled={currentPage === 0}
                        id="prev"
                    />
                    {renderPageNumbers(totalPaginas, currentPage, changePageValue)}
                    <Pagination.Next
                        disabled={currentPage === totalPaginas - 1}
                        onClick={() => changePageValue(currentPage + 1)}
                        id="next"
                    />
                    <Pagination.Last
                        disabled={currentPage > totalPaginas - 6}
                        onClick={() => changePageValue(totalPaginas - 1)}
                        id="last"
                    />
                </Pagination>
            </StyledDivPagination>
        </>
    );
}