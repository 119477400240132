import styled from "styled-components";
import { azulForte } from "../UI/variaveis";

export const StyledContainerLogin = styled.div`
    background-color: #fff;
    width: 40%;
    height: auto;
    padding: 40px;
    border-radius: 10px;
    min-width: 300px;
`;

export const StyledContainerBackground = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ${azulForte};
    color: white;
`;

