import moment from "moment";
import { INotaFiscal, ITicketReduzido } from "../../../../types/Agendamentos/agendamento.d";
import { addLineKeyToArrayItems } from "../../../../utils/utils";
import * as XLSX from 'xlsx';
import { IMetrica } from "../../../../types/Agendamentos/relatorio";
import { formatDateToBRL } from "../../../../utils/date";

export const enumMotivoBloqueioNotaFiscal = {
    "NAO_LOCALIZADA": "NÃO LOCALIZADA",
    "AGENDADA": "AGENDADA",
    "NAO_VERIFICADA": "NÃO VERIFICADA",
    "CHAVE_INVALIDA_CHAR": "CHAVE NAO CONTÉM 44 CARACTERES NUMÉRICOS",
    "CD_INCORRETO": "CD INCORRETO",
    "DEFAULT": "CHAVE VALIDA"
}

export function convertKeysToList(inputString: string): string[] {
    const keysSeparated = inputString.replaceAll('\n', ',').split(',');
    const keysArray = keysSeparated.map((key) => key.trim());
    return keysArray;
}

export const prepareKeysListForTable = (
    lista: string[],
    createTdChildren: (value: string) => INotaFiscal
) => {
    if (!lista || !lista.length) return [];
    let list = lista.map((item) => createTdChildren(item.trim()));
    list = addLineKeyToArrayItems(list as unknown as INotaFiscal[]);
    return list
}

export function removeNotasFiscaisDuplicadas(list1: INotaFiscal[], list2: INotaFiscal[]): INotaFiscal[] {
    const combinedList = [...list1, ...list2];
    const uniqueSet = new Set(combinedList);
    const uniqueArray = Array.from(uniqueSet);
    return addLineKeyToArrayItems(uniqueArray);
}

const mapTicketReduzidoToXlsx = (list: ITicketReduzido[]) => {
    return list?.map((ticket: ITicketReduzido) => ({
        'Número do Ticket': ticket.ticket,
        'Número de caixas': ticket.volumes,
        'CNPJ da Transportadora': ticket.cnpjTransportadora,
        'Nome da Transportadora': ticket.nomeTransportadora,
        'Data de Agendamento': moment(ticket.dataAgendamento).format('DD/MM/YYYY'),
        'Data de Previsão de Chegada': moment(ticket.dataPrevisaoChegada).format('DD/MM/YYYY'),
      }))
}

export const mapMetricasToTable = (metricas: IMetrica[]) => {
    return metricas?.map((metrica: IMetrica) => ({
        ...metrica,
        mesReferencia: formatDateToBRL(metrica.mesReferencia),
    }))
}

export const exportToXLSX = (data: any) => {
    const dataMapped = mapTicketReduzidoToXlsx(data)
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataMapped);

    ws['!cols'] = [
        { wch: 15 },
        { wch: 10 },
        { wch: 25 },
        { wch: 40 },
        { wch: 20 },
        { wch: 30 },
      ];

    XLSX.utils.book_append_sheet(wb, ws, 'Tickets');
    XLSX.writeFile(wb, 'tickets.xlsx');
}
