import GraphicReport from "../components/GraphicReport";
import TableRelatorios from "../components/_Agendamentos/Tables/TableRelatorios";

export const Relatorios = () => {
    return (
        <>
            <GraphicReport />
            <TableRelatorios />
        </>
    )
}