import { useEffect, useMemo, useState } from "react";
import { IsMobileContext } from "./IsMobileContext";

export const IsMobileProvider = ({ children }: { children: JSX.Element }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const verificaMobile = () => {
    if (window?.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const contextValue = useMemo(() => ({ isMobile }), [isMobile]);

  useEffect(() => {
    verificaMobile();
    window.addEventListener("resize", verificaMobile);
    return () => {
      window.removeEventListener("resize", verificaMobile);
    };
  }, []);

  return (
    <IsMobileContext.Provider value={contextValue}>
      {children}
    </IsMobileContext.Provider>
  );
};
