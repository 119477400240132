const returnUrl = () => {
  switch (import.meta.env.MODE) {
    case "development":
      return 'http://dock-scheduling-service.kb8s.hml.dimed.com.br/dock-scheduling-service/v1';
      // return `http://localhost:8080/dock-scheduling-service/v1`;
    case "production":
      return `/api`;
    default:
      return `http://localhost:8080/api`;
  }
};

export default returnUrl;
