import { all, takeEvery } from "redux-saga/effects";
import { deleteTicketByCodeSaga, getAgendamentosSaga, getDatasDisponiveisParaEntrega, getTicketByTicketNumberSaga, getTransportDetailsSaga, postAgendamentoSaga, putAgendamentoSaga, validateKeys } from "./agendamentos/sagas";
import { AgendamentosStoreTypes } from "./agendamentos/types";
import { resetPasswordSaga, sendNewPasswordSaga } from "./credentials/sagas";
import { CredentialsStoreTypes } from "./credentials/types";
import { getPreAgendamentosSaga, postConfirmaPreAgendamentoSaga, postPreAgendamentoSaga, putNewDatePreAgendamentoSaga, putStatusPreAgendamentoSaga } from "./preAgendamento/sagas";
import { PreAgendamentosStoreTypes } from "./preAgendamento/types";
import { getRelatoriosSaga } from "./relatorios/sagas";
import { RelatoriosStoreTypes } from "./relatorios/types";

export default function* rootSaga() {
  //@ts-ignore
  return yield all([
    // AGENDAMENTOS
    takeEvery(AgendamentosStoreTypes.GET_AGENDAMENTOS, getAgendamentosSaga),
    takeEvery(AgendamentosStoreTypes.GET_TRANSPORT_DETAILS, getTransportDetailsSaga),
    takeEvery(AgendamentosStoreTypes.POST_AGENDAMENTO, postAgendamentoSaga),
    takeEvery(AgendamentosStoreTypes.PUT_AGENDAMENTO, putAgendamentoSaga),
    takeEvery(AgendamentosStoreTypes.GET_VERIFY_KEYS, validateKeys),
    takeEvery(AgendamentosStoreTypes.GET_DATAS_DISPONIVEIS, getDatasDisponiveisParaEntrega),
    takeEvery(AgendamentosStoreTypes.GET_TICKET_BY_CODE, getTicketByTicketNumberSaga),
    takeEvery(AgendamentosStoreTypes.DELETE_TICKET_BY_CODE, deleteTicketByCodeSaga),

    // RELATORIOS
    takeEvery(RelatoriosStoreTypes.GET_RELATORIOS, getRelatoriosSaga),

    // AUTH
    takeEvery(CredentialsStoreTypes.RESET_PASSWORD, resetPasswordSaga),
    takeEvery(CredentialsStoreTypes.SEND_NEW_PASSWORD, sendNewPasswordSaga),

    // PRE-AGENDAMENTOS
    takeEvery(PreAgendamentosStoreTypes.POST_PRE_AGENDAMENTO, postPreAgendamentoSaga),
    takeEvery(PreAgendamentosStoreTypes.GET_PRE_AGENDAMENTOS, getPreAgendamentosSaga),
    takeEvery(PreAgendamentosStoreTypes.PUT_APROVACAO, putStatusPreAgendamentoSaga),
    takeEvery(PreAgendamentosStoreTypes.PUT_HANDLE_DATE, putNewDatePreAgendamentoSaga),
    takeEvery(PreAgendamentosStoreTypes.POST_CONFIRMA_PRE_AGENDAMENTO, postConfirmaPreAgendamentoSaga),
  ]);
}
