import styled from "styled-components";
import { IconButton, MenuItem, Select, Table, TableHead } from "@mui/material";
import { blue, cyan, green, red, yellow } from "@mui/material/colors";
import ReactDatePicker from "react-datepicker";
import { azulForte, azulForteEscuro } from "../../UI/variaveis";

export const StyledDivNoContent = styled.tfoot`
text-align: center;
border: 1px solid #3f3f3f33;
padding: 6px;
`

export const StyledMenuItemFilter = styled(MenuItem)`
    display: flex;
    alignItems: center;
    justifyContent: space-between;
    width: 100%;
    padding: 0 16px 0 0;
`;

export const StyledSelectFilter = styled(Select)`
    opacity: 0;
    position: absolute;
    top: 0;
    left: -100px;
    bottom: 0;
    right: 0;
`;

export const StyledTableHead = styled(TableHead)`
font-size: 2rem !important;
background-color: ${azulForte};
font-weight: bold;
`;

export const StyledTable = styled(Table)`
min-width: 925px;
border-radius: 5px;
overflow: hidden;

& span {
    font-size: 1.6rem;
    &.check { color: ${green[700]}; }
    &.cancel { color: ${red[500]}; }
}

& .custom-date-input {
    border: none;
    background-color: transparent;
    padding: 0;
    margin-right: 10px;
}
& .control-table { padding: 0; }

& th {
        color: #fff;
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        padding: 14px 10px 10px;
        white-space: nowrap;
        border: none !important;
}

& thead th:first-of-type {
    svg {
        width: 40px;
        height: 40px;
    }
}

& td {
    text-align: center;
    padding: 14px 10px 10px;
    font-size: 1.2rem;
    border-right: solid 0.1px #3f3f3f33;
    border-left: solid 0.1px #3f3f3f33;

    &:last-of-type { border-left: none; }

    &:first-of-type {
        border-left: none;

        & svg {
            width: 32px;
            height: 32px;        
        }
    }

    & span {
        margin: 0;
        cursor: pointer;
        &.print-button { color: ${blue[500]}; };
        &.edit-button { color: ${yellow[800]}; };
        &.delete-button { color: ${red[500]}; };
        &.export-button { color: ${cyan[700]}; };
        &.print-button:hover,
        &.edit-button:hover,
        &.delete-button:hover,
        &.export-button:hover { color: ${azulForteEscuro}; };
        &.calendar-button {
            color: ${blue[600]};
            position: absolute;
            margin-right: 20px;
        };
    }
}
`;

interface IconButtonProps {
    disabled?: boolean
}

export const StyledIconButton = styled(IconButton)(({ disabled }: IconButtonProps) => ({
    opacity: disabled ? 0.3 : 1,
    padding: 0,
}));

export const StyledTableFoot = styled.div`
display: flex;
padding: 6px 10px;
font-size: 1.2rem;
justify-content: ${({ length }: { length: boolean }) => {
        return length ? 'flex-end;' : 'center;';
    }};
}
`;

export const StyledDiv = styled.div`
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
gap: 10px;

& div.react-datepicker__day--selected {
    background-color: ${azulForte};
}
`;

export const StyledDatePicker = styled(ReactDatePicker)`
    cursor: pointer;

    ${props => props?.disabled && `
      background-color: #e9ecef;
      cursor: not-allowed;
    `}
`;

export const StyledDateField = styled.button`
border: none;
background-color: transparent;
padding: 0;
margin-right: 10px;
`;