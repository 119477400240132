import api from "./api";

export const useApi = () => ({
  validadeToken: async (token: string) => {
    const response = await api.post('/validate', { token });
    return response.data;
  },
  signin: async (username: string, password: string) => {
    const response = await api.post('/auth', { username, password });
    return response.data;
  },
  logout: async () => {
    const response = await api.post('/logout');
    return response.data;
  }
 
})
