import { yupResolver } from '@hookform/resolvers/yup';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { Checkbox, FormControl, Grid, IconButton, Input, Paper, TableBody, TableCell, TableContainer, TableFooter, TableRow } from '@mui/material';
import moment from 'moment';
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PreAgendamentoContext } from '../../../contexts/PreAgendamento/PreAgendamentoContext';
import { isAdminEmail } from '../../../service/admin';
import { ApplicationState } from '../../../store';
import { PreAgendamentosState } from '../../../store/ducks/preAgendamento/types';
import { PreAgendamentoRowResponse } from '../../../types/PreAgendamentos/preAgendamento';
import { getCurrentUserValues } from '../../../utils/auth';
import { dateIsNotValidPreAgendamento } from '../../../utils/date';
import { filtroDeDatasDesejadas, toSortedPreAgendamentos } from '../../../utils/lists';
import { formatCNPJ } from '../../../utils/strings';
import GenericModal from '../../GenericModal';
import { StyledContainerForResponsive } from '../../UI/containers';
import { StyledSearchIconFlex } from '../../UI/icons';
import { StyledTitleCharcoal } from '../../UI/titles';
import AdvancedSearch from '../../_Agendamentos/Tables/AdvancedSearch';
import CustomPagination from '../../_Agendamentos/Tables/Pagination';
import { linesWithPagination } from '../../_Agendamentos/Tables/service';
import { StyledDivSearch } from '../../_Agendamentos/Tables/styles';
import { EnumStatusPreAgendamento } from './enum';
import { TableChangeDateSchema } from './schema';
import { StyledDatePicker, StyledDiv, StyledIconButton, StyledMenuItemFilter, StyledSelectFilter, StyledTable, StyledTableFoot, StyledTableHead } from './styles';

const CustomDatepicker = forwardRef<HTMLButtonElement, { value: string; onClick: (e: any) => void }>(({ value, onClick }, ref) => (
    <button id="data-input-change-button" className="custom-date-input" onClick={onClick} ref={ref}>{value}</button>
));

interface ITableDateChange {
    data: string;
}

function TablePreAgendamentos() {
    const dispatch = useDispatch();
    const [maxItemsPerPage, setMaxItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [searchValue, setSearchValue] = useState("");
    const valorMinimoPaginacao: number = 10;
    const { loading, preAgendamentosList } = useSelector<ApplicationState, PreAgendamentosState>((state) => state?.preAgendamentos);
    const [preAgendamentoSelecionado, setPreAgendamentoSelecionado] = useState<PreAgendamentoRowResponse>();
    const { setPreAgendamentoContext } = useContext(PreAgendamentoContext);
    const [preAgendamentoList, setPreAgendamentoList] = useState<PreAgendamentoRowResponse[]>([]);
    const [totalPaginas, setTotalPaginas] = useState<number>(0);
    const navigate = useNavigate();
    const [openModalExclude, setOpenModalExclude] = useState(false);
    const [filterCd, setFilterCd] = useState<number[]>([1007, 1017, 1021]);
    const selectRef = useRef<HTMLSelectElement>(null);
    const decodedToken = getCurrentUserValues();
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalDate, setOpenModalDate] = useState(false);
    const [datasDisponiveis, setDatasDisponiveis] = useState<string[]>([]);
    const [newDate, setNewDate] = useState<Date>(new Date());

    const {
        register,
        control,
        watch,
    } = useForm<ITableDateChange>({ resolver: yupResolver(TableChangeDateSchema) });

    useEffect(() => {
        dispatch({ type: "@preAgendamentos/GET_PRE_AGENDAMENTOS" })
    }, []);

    useEffect(() => {
        if (preAgendamentosList?.length) {
            setPreAgendamentoList(linesWithPagination(toSortedPreAgendamentos(preAgendamentosList), currentPage, maxItemsPerPage));
        }
    }, [preAgendamentosList])

    useEffect(() => {
        const filteredPreAgendamentos = filterPreAgendamentos(toSortedPreAgendamentos(preAgendamentosList), filterCd, searchValue)
        setTotalPaginas(Math.ceil(filteredPreAgendamentos?.length / maxItemsPerPage));
    }, [preAgendamentoList, maxItemsPerPage])

    useEffect(() => {
        const filteredPreAgendamentos = filterPreAgendamentos(toSortedPreAgendamentos(preAgendamentosList), filterCd, searchValue)
        setCurrentPage(0);
        setPreAgendamentoList(linesWithPagination(toSortedPreAgendamentos(preAgendamentosList), currentPage, maxItemsPerPage));
        setTotalPaginas(Math.ceil(filteredPreAgendamentos?.length / maxItemsPerPage));
    }, [maxItemsPerPage])

    useEffect(() => {
        setCurrentPage(0);
        const filteredPreAgendamentos = filterPreAgendamentos(toSortedPreAgendamentos(preAgendamentosList), filterCd, searchValue)
        if (!filterCd?.length || filterCd?.length && filteredPreAgendamentos?.length && !filteredPreAgendamentos?.some((preAgendamento: PreAgendamentoRowResponse) => filterCd?.toString()?.includes(preAgendamento?.cd?.toString()))) {
            toast.info("Nenhum pré-agendamento encontrado para o(s) CD(s) selecionado(s).");
        }
        const filteredPreAgendamentoBySearch = filteredPreAgendamentos?.filter((preAgendamento: PreAgendamentoRowResponse) => filterCd?.toString()?.includes(preAgendamento?.cd?.toString()));
        setPreAgendamentoList(linesWithPagination(toSortedPreAgendamentos(filteredPreAgendamentoBySearch), 0, maxItemsPerPage));
        setTotalPaginas(Math.ceil(filteredPreAgendamentoBySearch?.length / maxItemsPerPage));
    }, [filterCd])

    useEffect(() => {
        const filteredPreAgendamentos = filterPreAgendamentos(preAgendamentosList, filterCd, searchValue)
        setPreAgendamentoList(linesWithPagination(toSortedPreAgendamentos(filteredPreAgendamentos), 0, maxItemsPerPage));
        setTotalPaginas(Math.ceil(filteredPreAgendamentos?.length / maxItemsPerPage));
    }, [searchValue])

    const filterPreAgendamentos = (listaDePreAgendamentos: PreAgendamentoRowResponse[], filterCd: number[], searchValue: string) => {
        return listaDePreAgendamentos?.filter((preAgendamento: PreAgendamentoRowResponse) => {
            return preAgendamento?.id?.toString().includes(searchValue) && filterCd?.toString()?.includes(preAgendamento?.cd?.toString());
        });
    }

    const changePageValue = (numeroDaPagina: number) => {
        if (numeroDaPagina === currentPage) return
        setCurrentPage(numeroDaPagina);
        setPreAgendamentoList(linesWithPagination(toSortedPreAgendamentos(preAgendamentosList), numeroDaPagina, maxItemsPerPage));
    }

    const handleDeleteAgendamento = (preAgendamento: PreAgendamentoRowResponse) => {
        if (!preAgendamento) return;
        dispatch({
            type: "@preAgendamentos/DELETE_TICKET_BY_CODE",
            payload: { id: preAgendamento?.id },
            onSuccess: () => toast.success(`Pré-agendamento n° ${preAgendamento.id} excluído com sucesso!`),
            onError: () => { }
        })
    }

    const onSuccessReprove = () => {
        toast.success("Pré-Agendamento reprovado com sucesso!");
        dispatch({ type: "@preAgendamentos/GET_PRE_AGENDAMENTOS" });
        navigate('/pre-agendamentos');
    }

    const onSuccessApprove = () => {
        toast.success("Pré-agendamento aprovado com sucesso!")
        dispatch({ type: "@preAgendamentos/GET_PRE_AGENDAMENTOS" })
        navigate('/pre-agendamentos')
    }

    const onErrorApproving = (preAgendamento: PreAgendamentoRowResponse) => {
        setPreAgendamentoSelecionado(preAgendamento);
        setOpenModalConfirm(true)
    }

    const onErrorHandlingDate = (preAgendamento: PreAgendamentoRowResponse) => {
        setPreAgendamentoSelecionado(preAgendamento);
        setOpenModalDate(true);
    }

    const onSuccessVerifyDate = () => {
        toast.success("Data de pré-agendamento alterada com sucesso!")
        dispatch({ type: "@preAgendamentos/GET_PRE_AGENDAMENTOS" })
        setPreAgendamentoSelecionado(undefined)
        navigate('/pre-agendamentos')
    }

    const handleApprove = (preAgendamento: PreAgendamentoRowResponse, approve: boolean, aprovacaoAdmin: boolean = false) => {
        dispatch({
            type: "@preAgendamentos/PUT_APROVACAO",
            payload: {
                id: preAgendamento?.id,
                status: approve ? "APROVADO" : "REPROVADO",
                aprovacaoAdmin
            },
            onSuccess: approve ? onSuccessApprove : onSuccessReprove,
            onError: approve ? () => { onErrorApproving(preAgendamento) } : () => { }
        })
    }

    const onChangeDate = (newDate: any, preAgendamento: PreAgendamentoRowResponse, aprovacaoAdmin: boolean = false) => {
        setNewDate(newDate);

        if (datasDisponiveis?.includes(moment(newDate).format("YYYY-MM-DD")) && !aprovacaoAdmin) {
            setOpenModalDate(true);
            return;
        }

        dispatch({
            type: "@preAgendamentos/PUT_HANDLE_DATE",
            payload: {
                id: Number(preAgendamento?.id),
                data: moment(newDate).format("YYYY-MM-DD"),
                aprovacaoAdmin
            },
            onSuccess: onSuccessVerifyDate,
            onError: () => onErrorHandlingDate(preAgendamento)
        })
    }

    const onCloseModalExclude = () => {
        setPreAgendamentoSelecionado(undefined)
        setOpenModalExclude(false)
    }

    const onClickEdit = (preAgendamento: PreAgendamentoRowResponse) => {
        setPreAgendamentoSelecionado(preAgendamento);
        setOpenModalExclude(true);
    }

    const onConfirmApprove = () => preAgendamentoSelecionado && handleApprove(preAgendamentoSelecionado, true, true);

    const onCloseModalApprove = () => {
        setPreAgendamentoSelecionado(undefined);
        setOpenModalConfirm(false);
    }

    const filterCdParams = {
        label: 'Centro de Distribuição',
        key: 'filtro-cd',
        type: 'select',
        options: [
            {
                label: '1007-ES',
                value: 1007,
                checked: filterCd?.toString()?.includes('1007'),
            },
            {
                label: '1017-PR',
                value: 1017,
                checked: filterCd?.toString()?.includes('1017'),
            },
            {
                label: '1021-RS',
                value: 1021,
                checked: filterCd?.toString()?.includes('1021'),
            }
        ],
        value: filterCd,
    }

    const isValidAdmin = (preAgendamento: PreAgendamentoRowResponse) => {
        return Boolean(isAdminEmail(decodedToken?.email) && EnumStatusPreAgendamento[preAgendamento?.status as keyof typeof EnumStatusPreAgendamento] === EnumStatusPreAgendamento.AGUARDANDO_APROVACAO)
    }

    const renderSearchInput = () => {
        return (
            <StyledDivSearch key="search-pre-agendamento-div">
                <StyledSearchIconFlex onClick={() => {
                    document.getElementById("search-input")?.focus();
                }} id="search-pre-agendamento" />
                <Input
                    id="search-input"
                    placeholder="Pesquisar por Pré-Agendamento"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    data-cy="search-pre-agendamento"
                />
            </StyledDivSearch>
        )
    }

    const renderTableFooter = () => {
        return (
            <StyledTableFoot
                length={Boolean(preAgendamentosList?.length)}
                id="table-footer-pre"
                data-cy="table-footer-pre"
            >
                {!preAgendamentosList?.length && (<div className="text-center">Nenhum registro localizado.</div>)}
                {(preAgendamentosList?.length && preAgendamentosList?.length >= valorMinimoPaginacao)
                    ? <>
                        <label htmlFor='max-per-page-select' className="p-2 text-center">Máximo de linhas por pagina:</label>
                        <select
                            style={{
                                backgroundColor: "#fff",
                                height: "40px",
                                borderRadius: "5px"
                            }}
                            id="max-per-page-select"
                            title="Máximo de linhas por pagina"
                            value={maxItemsPerPage}
                            onChange={(e) => setMaxItemsPerPage(Number(e.target.value))}
                        >
                            <option value="10">10</option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                        </select>
                    </> : null}
            </StyledTableFoot>)
    }

    const renderControlApproveButtons = (preAgendamento: PreAgendamentoRowResponse) => {
        const waitingAction = EnumStatusPreAgendamento[preAgendamento?.status as keyof typeof EnumStatusPreAgendamento] === EnumStatusPreAgendamento.AGUARDANDO_APROVACAO;
        const isDateInvalid = dateIsNotValidPreAgendamento(preAgendamento?.data);
        const isAdmin = isAdminEmail(decodedToken?.email);
        return (
            <Grid
                style={{ display: 'flex', justifyContent: 'center', gap: "10px" }}
                item
                xs={1}
            >
                <StyledIconButton
                    disabled={EnumStatusPreAgendamento[preAgendamento?.status as keyof typeof EnumStatusPreAgendamento] === EnumStatusPreAgendamento.REPROVADO || waitingAction && isDateInvalid || !isAdmin}
                    onClick={() => {
                        if (waitingAction && !loading) {
                            handleApprove(preAgendamento, true)
                        }
                    }}
                >
                    <span className="material-symbols-outlined check">check_circle</span>
                </StyledIconButton>
                <StyledIconButton
                    disabled={EnumStatusPreAgendamento[preAgendamento?.status as keyof typeof EnumStatusPreAgendamento] === EnumStatusPreAgendamento.APROVADO || waitingAction && isDateInvalid || !isAdmin}
                    onClick={() => {
                        if (waitingAction && !loading) {
                            handleApprove(preAgendamento, false)
                        }
                    }}
                >
                    <span className="material-symbols-outlined cancel">cancel</span>
                </StyledIconButton>
            </Grid>
        )
    }

    const renderPagination = () => {
        if (preAgendamentosList?.length > valorMinimoPaginacao) {
            return (<CustomPagination
                currentPage={currentPage}
                changePageValue={changePageValue}
                totalPaginas={totalPaginas}
                key="pagination-pre-agendamentos"
            />)
        }
    }

    const renderFilterCd = (filter: any) => {
        return (
            <FormControl sx={{ border: 'none !important' }}>
                <IconButton style={{ color: '#fff' }}>
                    <FilterListIcon />
                </IconButton>
                <StyledSelectFilter
                    ref={selectRef}
                    value={filterCd}
                    multiple
                    onChange={(e) => setFilterCd(e.target.value as number[])}
                    displayEmpty
                    renderValue={() => <></>}
                    MenuProps={{ PaperProps: {} }}
                >
                    {filter?.options?.map((opt: any) => (
                        <StyledMenuItemFilter key={`menu-item-${opt.value}`} value={opt.value}>
                            <Checkbox checked={opt.checked} />
                            {opt.label}
                        </StyledMenuItemFilter>
                    ))}
                </StyledSelectFilter>
            </FormControl>
        );
    };

    const renderControlButtons = (preAgendamento: PreAgendamentoRowResponse) => {
        const isDateInvalid = dateIsNotValidPreAgendamento(preAgendamento?.data);
        const waitingAction = EnumStatusPreAgendamento[preAgendamento?.status as keyof typeof EnumStatusPreAgendamento] === EnumStatusPreAgendamento.AGUARDANDO_APROVACAO;
        return (
            <StyledDiv>
                <StyledIconButton
                    disabled={!waitingAction}
                    onClick={() => onClickEdit(preAgendamento)}
                >
                    <span title="Excluir pŕe-agendamento" className="material-symbols-outlined delete-button">delete</span>
                </StyledIconButton>
                <StyledIconButton
                    disabled={preAgendamento?.status !== EnumStatusPreAgendamento.APROVADO || isDateInvalid}
                    onClick={() => {
                        setPreAgendamentoContext(preAgendamento)
                        navigate("/pre-agendamento-notas")
                    }}>
                    <span title='Adicionar notas' className="material-symbols-outlined edit-button">playlist_add</span>
                </StyledIconButton>
            </StyledDiv>
        );
    };

    const handleClickDataChange = (preAgendamento: PreAgendamentoRowResponse) => {
        setPreAgendamentoSelecionado(preAgendamento);
        const inputElement = document.getElementById('data-input-change-button') as HTMLInputElement;
        dispatch({
            type: '@agendamentos/GET_DATAS_DISPONIVEIS',
            payload: {
                cd: preAgendamento?.cd,
                volume: preAgendamento?.volume,
                quantidadeSku: preAgendamento?.quantidadeSku,
            },
            onSuccess: (res: string[]) => res && setDatasDisponiveis(res),
        });
        inputElement?.click();
    }

    const onConfirmForceDateChange = () => {
        preAgendamentoSelecionado && onChangeDate(newDate, preAgendamentoSelecionado, true)
    }

    const onCloseModalDataChange = () => {
        setOpenModalDate(false);
    }

    const renderEditDateCell = (preAgendamento: PreAgendamentoRowResponse) => {
        const isDisabled = preAgendamento?.status !== "AGUARDANDO_APROVACAO";
        return (<Controller
            key={`date-control-data-input-change`}
            control={control}
            name={'data'}
            render={({ field }) => (
                <StyledDiv>
                    <StyledIconButton disabled={isDisabled} onClick={() => handleClickDataChange(preAgendamento)}>
                        <span className="material-symbols-outlined calendar-button">edit_calendar</span>
                    </StyledIconButton>
                    <StyledDatePicker
                        customInput={<CustomDatepicker value={watch("data")} onClick={(e: any) => handleClickDataChange(preAgendamento)} />}
                        id={'data-input-change'}
                        locale="ptBr"
                        fixedHeight
                        filterDate={filtroDeDatasDesejadas}
                        dateFormat="dd/MM/yyyy"
                        selected={moment(preAgendamento?.data).utc().toDate()}
                        {...register("data")}
                        onChange={(date: Date | null) => onChangeDate(date, preAgendamento)}
                    />
                </StyledDiv>
            )}
        />);
    }

    return (
        <StyledContainerForResponsive id="table-pre-agendamentos-container" className="margin-header" maxWidth="xl">
            <StyledTitleCharcoal variant="h4" component="h4" gutterBottom>Consulta pré-agendamentos</StyledTitleCharcoal>
            <AdvancedSearch childrens={[renderSearchInput()]} />
            <TableContainer component={Paper}>
                <StyledTable id="table-pre-agendamentos" data-cy="table-pre-agendamentos" aria-label="Tabela de Pré-Agendamentos">
                    <StyledTableHead>
                        <TableRow>
                            <TableCell>
                                <MoreTimeIcon style={{
                                    cursor: 'pointer',
                                    fontSize: '2rem',
                                    width: '100%',
                                }} titleAccess='Novo Pré Agendamento' onClick={() => navigate('/pre-agendamento')} /></TableCell>
                            <TableCell>Pré-agenda</TableCell>
                            <TableCell>Data programação</TableCell>
                            <TableCell>Nome transportadora</TableCell>
                            <TableCell>CNPJ transportadora</TableCell>
                            <TableCell>Número de caixas</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell sx={{
                                color: "#fff",
                                display: 'flex',
                                height: '100%',
                                width: '100%',
                            }} >
                                <div className="p-2">Filial</div>
                                {renderFilterCd(filterCdParams)}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {preAgendamentoList?.map((preAgendamento, index) => (
                            <TableRow key={preAgendamento?.id}>
                                <TableCell>{renderControlButtons(preAgendamento)}</TableCell>
                                <TableCell>{preAgendamento?.id}</TableCell>
                                <TableCell>{isValidAdmin(preAgendamento) ? renderEditDateCell(preAgendamento) : moment(preAgendamento.data).format("DD/MM/YYYY")}</TableCell>
                                <TableCell>{preAgendamento?.nomeTransportadora?.length > 25 ? preAgendamento.nomeTransportadora?.substring(0, 25) + "..." : preAgendamento.nomeTransportadora}</TableCell>
                                <TableCell>{formatCNPJ(preAgendamento?.cnpj)}</TableCell>
                                <TableCell>{preAgendamento?.volume}</TableCell>
                                <TableCell>{preAgendamento?.quantidadeSku}</TableCell>
                                <TableCell>{preAgendamento?.cd}</TableCell>
                                <TableCell>{renderControlApproveButtons(preAgendamento)}</TableCell>
                            </TableRow >
                        ))}
                    </TableBody >
                    <TableFooter>
                        <TableRow>
                            <TableCell
                                sx={{
                                    padding: "0 !important",
                                    backgroundColor: "#E0E0E0",
                                }}
                                colSpan={12}>
                                {renderTableFooter()}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </StyledTable >
            </TableContainer >
            {Boolean(preAgendamentoList?.length) && renderPagination()}
            <GenericModal
                type="alert"
                onClose={onCloseModalExclude}
                onConfirm={() => preAgendamentoSelecionado && handleDeleteAgendamento(preAgendamentoSelecionado)}
                open={openModalExclude}
                title="Deseja realmente excluir?"
                message="Deseja realmente excluir este pré-agendamento?"
            />
            <GenericModal
                onClose={onCloseModalApprove}
                onConfirm={onConfirmApprove}
                open={openModalConfirm}
                title="Deseja realmente confirmar?"
                message="A aprovação desta pré-agenda irá ultrapassar os limites de Número de caixas/SKU para esta data"
            />
            <GenericModal
                onClose={onCloseModalDataChange}
                onConfirm={onConfirmForceDateChange}
                open={openModalDate}
                title="Deseja realmente alterar a data?"
                message={
                    moment(newDate).isAfter(moment(datasDisponiveis?.[-1]))
                        ? "Você está alterando a data deste pré-agendamento, deseja realmente prosseguir com esta ação?"
                        : "A data selecionada para esta pré-agenda irá ultrapassar os limites de Número de caixas/SKU para esta data."}
            />
        </StyledContainerForResponsive >
    );
}

export default TablePreAgendamentos;