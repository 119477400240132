import { createContext } from "react";
import { INotaFiscal, IResponseAgendamento, ITicket, ITicketReduzido } from "../../types/Agendamentos/agendamento.d";

export const AgendamentoContext = createContext({
  listaDeChaves: [] as string[],
  setListaDeChaves: (props: string[]) => { },
  listaDeNotasFiscais: [] as INotaFiscal[],
  setListaDeNotasFiscais: (props: INotaFiscal[]) => { },
  ticket: {} as ITicket,
  setTicket: (props: IResponseAgendamento) => { },
  listaDeRelatorios: [] as ITicketReduzido[],
  setListaDeRelatorios: (props: ITicketReduzido[]) => { },
});
