import styled from "styled-components";
import { Col } from "react-bootstrap";
import { azulForte, azulForteEscuro } from "../../UI/variaveis";

export const StyledLogo = styled.img`
    max-width: 600px;
    margin: 0 auto;
    @media print {
        max-width: 300px;
    }
`;

export const StyledFlexRow = styled.div`
    display: flex;
    width: 100%;
    min-width: 100%;

    &.background-gray {
        min-width: 100%;
        background: linear-gradient(#f2f2f2, #d9d9d9);
        border-radius: 5px;
        padding: 10px;
        justify-content: space-around;
        white-space: nowrap;
    }
`;

export const StyledTicketLine = styled(StyledFlexRow)`
    background-color: #1d2357;
    color: #fefefe;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    min-width: 678px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 0 20px;
`;

export const StyledCol = styled(Col)`
padding: 0 20px;
& .content {
    padding: 10px
    white-space: nowrap;

    &.flex {
        display: flex;
        justify-content: flex-start;
    }

    &.col {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    & .content__label {
        margin: 0;
        text-align: center;
        font-weight: 600;
    }

    & .content__value {
        margin: 0;
        font-weight: 400;
    }

    & .font-weight-bold {
        font-weight: 600;
    }
}

& .border-bottom {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #1d2357 !important;
}
`;

export const StyledBorder = styled.div`
    border-bottom: 2px solid #1d2357;
    width: 100%;
    padding: 0 20px;
`;

export const StyledDivButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;
    margin: 20px 0;
    padding: 0 20px;
    width: 100%;
    min-width: 678px;
    white-space: nowrap;

    @media print {
        display: none;
    }
`;