import { PreAgendamentoRowResponse } from "../../../types/PreAgendamentos/preAgendamento";

export enum PreAgendamentosStoreTypes {
  POST_PRE_AGENDAMENTO = "@preAgendamentos/POST_PRE_AGENDAMENTO",
  POST_CONFIRMA_PRE_AGENDAMENTO = "@preAgendamentos/POST_CONFIRMA_PRE_AGENDAMENTO",
  PUT_APROVACAO = "@preAgendamentos/PUT_APROVACAO",
  GET_PRE_AGENDAMENTOS = "@preAgendamentos/GET_PRE_AGENDAMENTOS",
  PUT_HANDLE_DATE = "@preAgendamentos/PUT_HANDLE_DATE",
  LOADING_SUCCESS = "@preAgendamentos/LOADING_SUCCESS",
  LOADING_FAILURE = "@preAgendamentos/LOADING_FAILURE",
}

export interface PreAgendamentosState {
  readonly preAgendamentosList: PreAgendamentoRowResponse[];
  readonly loading: boolean;
}
