import { INotaFiscal } from "../../types/Agendamentos/agendamento.d";
import { addLineKeyToArrayItems } from "../../utils/utils";

export const enumMotivoBloqueioNotaFiscal = {
    "NAO_LOCALIZADA": "NAO_LOCALIZADA",
    "AGENDADA": "AGENDADA",
    "CD_INCORRETO": "CD_INCORRETO",
    "NAO_VERIFICADA": "NAO_VERIFICADA",
}

export const enumMessageMotivoBloqueioNotaFiscal = {
    "NAO_LOCALIZADA": "NÃO LOCALIZADA",
    "AGENDADA": "AGENDADA",
    "NAO_VERIFICADA": "NÃO VERIFICADA",
    "CHAVE_INVALIDA_CHAR": "NÃO CONTÉM 44 CARACTERES NUMÉRICOS",
    "CD_INCORRETO": "CD INCORRETO",
    "DEFAULT": "VALIDA"
}

export const enumMessagesAgendamento = {
    "CHAVES_CD_INCORRETO": "Alguma(s) chave(s) estão com o centro de distribuição incorreto",
    "CHAVES_JA_AGENDADAS": "Alguma(s) chave(s) já estão agendadas",
    "CHAVES_VERIFICADAS": "Chaves verificadas com sucesso",
    "CHAVES_NAO_LOCALIZADAS": "Alguma(s) chave(s) não foram localizada(s)",
    "TRANSPORTADORA_ENCONTRADA": "Transportadora selecionada com sucesso",
    "CHAVES_REMOVE_INVALIDAS": "Remova da lista as chaves inválidas para prosseguir",
    "SUCCESS_VOLUME_SKU": "Número de caixas e SKU atualizados com sucesso",
}

export function removeNotasFiscaisDuplicadas(list1: INotaFiscal[], list2: INotaFiscal[]): INotaFiscal[] {
    const combinedList = [...list1, ...list2];
    const uniqueSet = new Set(combinedList);
    const uniqueArray = Array.from(uniqueSet);
    return addLineKeyToArrayItems(uniqueArray);
}
