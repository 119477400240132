import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Panvel from "../../assets/img/logo-panvel.svg";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { ILogin } from "../../types/Login";
import { FormFieldTypesEnum } from "../Forms/render";
import { StyledForm } from "../Forms/styles";
import { InputType, PasswordType } from "../Forms/types";
import { StyledButtonSubmit } from "../UI/buttons";
import { LoginSchema } from "./schema";
import { StyledContainerBackground, StyledContainerLogin } from "./styles";

export const FormLogin = () => {
    const auth = useContext(AuthContext);
    const { loading } = auth;
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm<ILogin>({ resolver: yupResolver(LoginSchema) });
    const [values, setValues] = useState({
        showPassword: false,
    });

    const INPUTS: InputType[] = [
        {
            label: <Typography className="text-dark" variant="h6">Email</Typography>,
            type: 'text',
            name: 'email',
            register,
            errors,
            placeholder: 'email@email.com',
        }
    ]

    const INPUT_PASSWORD: PasswordType[] = [
        {
            label: <Typography className="text-dark" variant="h6">Senha</Typography>,
            type: 'password',
            name: 'password',
            register,
            errors,
            placeholder: '********',
            onClickShowPassword: () => setValues({
                ...values,
                showPassword: !values.showPassword
            }),
            showPassword: values.showPassword,
            value: watch('password') || '',
        }
    ];

    const onEnterKeyPressed = (e: any) => {
        if (e.key === 'Enter') {
            handleSubmit(handleLogin)();
        }
    };

    const handleLogin = ((data: any) => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("admin");
        localStorage.removeItem("cnpj");
        const { password, email } = data;
        const isLogged = auth.signin(email, password)
            .then((res) => {
                if (!!isLogged && res) navigate("/agendamentos");
            })
            .catch(() => toast.error("E-mail ou senha inválidos."));
    });

    return (
        <StyledContainerBackground>
            <img height={80} className="img-logo-login" src={Panvel} alt="Logo" />
            <StyledContainerLogin className="flex-col">
                <StyledForm onKeyDown={(event: any) => onEnterKeyPressed(event)} id="form-login" className="form-login pb-1" >
                    {FormFieldTypesEnum.INPUT(INPUTS)}
                    {FormFieldTypesEnum.INPUT_PASSWORD(INPUT_PASSWORD)}
                </StyledForm>
                <StyledButtonSubmit disabled={loading} onClick={handleSubmit(handleLogin)} className="button-submit w-100 mt-3"
                    type="submit"
                >
                    {loading ? <CircularProgress /> : "Entrar"}
                </StyledButtonSubmit>
            </StyledContainerLogin>
        </StyledContainerBackground>
    )
}
