import { call, put } from "redux-saga/effects";
import { getRelatorios } from "../../../service/schedule.service";
import { notifyErrorStatus } from "../service";
import { RelatoriosStoreTypes } from "./types";

export function* getRelatoriosSaga(action: any): any {
  try {
    const res = yield call(getRelatorios);
    action?.onSuccess(res?.data);
    yield put({ type: RelatoriosStoreTypes.LOAD_RELATORIOS, payload: res?.data })
  } catch (error: any) {
    yield put({ type: RelatoriosStoreTypes.LOADING_FAILURE })
    notifyErrorStatus(error);
  }
}