export const filterToOnlyNumbers = (value: string) => {
    if (!value) return "";
    return value?.replace(/\D/g, '');
};


export const convertNumberToString = (valueField: string | number) => {
  let data = valueField;
  if (typeof valueField === "number") {
      data = valueField.toString();
  }
  if (typeof valueField === "string") {
    data = valueField.replace(/\D/g, "");
  }
  return data;
};

export const convertStringToNumber = (valueField: string | number) => {
  if (typeof valueField === "string" && Number(valueField) > 0) {
    return Number(valueField);
  } else {
    return valueField;
  }
};

export const formatPhoneNumberToMask = (inputValue: string): string => {
      const cleanedValue = inputValue?.replace(/\D/g, '');
      const regex = /^(\d{2})(\d{1})(\d{4})(\d{4})$/;
      const formattedValue = cleanedValue?.replace(regex, '($1) $2.$3-$4');

      return formattedValue;
    }

  export const formatCNPJ = (value: string): string => {
    if (!value) return "";
    const cleanedValue = value?.replace(/\D/g, '');
    const regex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
    const formattedValue = cleanedValue?.replace(regex, '$1.$2.$3/$4-$5');
  
    return formattedValue;
  }

  export const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject(new Error("Falha ao ler o arquivo."));
        }
      };
      reader.onerror = () => {
        reject(new Error("Falha ao ler o arquivo."));
      };
      reader.readAsText(file);
    });
  }