import { PreAgendamentoRequest, PreAgendamentoStatusRequest, PreAgendamentoNotasRequest, PreAgendamentoEditDateRequest } from "../types/PreAgendamentos/preAgendamento";
import api from "./apiTs";

export const postPreAgendamento = async (data: PreAgendamentoRequest) => {
    return await api.post(`/pre-agendamento`, data)
}

export const getPreAgendamentos = async () => {
    return await api.get(`/pre-agendamento`)
}

export const putStatusPreAgendamento = async (data: PreAgendamentoStatusRequest) => {
    return await api.put(`/pre-agendamento/status`, data)
}

export const putDataPreAgendamento = async (data: PreAgendamentoEditDateRequest) => {
    return await api.put(`/pre-agendamento/data`, data)
}

export const postConfirmaPreAgendamento = async (data: PreAgendamentoNotasRequest) => {
    return await api.post(`/pre-agendamento/confirma`, data)
}