
export enum AuthStoreTypes {
  SIGNIN = "@AUTH/SIGNIN",
  LOGOUT = "@AUTH/LOGOUT",
  LOADING_SUCCESS = "@AUTH/LOADING_SUCCESS",
  LOADING_FAILURE = "@AUTH/LOADING_FAILURE",
}

export interface AuthState {
  readonly loading: boolean;
}