import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { ApplicationState } from "../../store";
import { RelatorioState } from "../../store/ducks/relatorios/types";
import { StyledListItemIcon } from "../Sidebar/Sidebar";
import { StyledContainerForResponsive } from "../UI/containers";
import { StyledTitleCharcoal } from "../UI/titles";
// import { Chart } from "react-google-charts";
import { StyledBarChart } from "./styles";
import { mapMetricasToTable } from "../_Agendamentos/Tables/TableRelatorios/service";

export const GraphicReport: React.FC = () => {
  const { metricas } = useSelector<ApplicationState, RelatorioState>((state) => state.relatorios);
  const [width, setWidth] = useState(window.innerWidth - 300);
  const [listOfGraphs, setListOfGraphs] = useState<any[]>([]);

  const callbackGetMetricas = useCallback(() => {
    setListOfGraphs(mapMetricasToTable(metricas));
  }, [metricas]);

  useEffect(() => {
    callbackGetMetricas()
  }, [metricas]);

  useEffect(() => {
    setWidth(window.innerWidth - 300);
  }, [window.innerWidth]);

  return (
    <StyledContainerForResponsive className="margin-header" maxWidth="xl">
      <StyledTitleCharcoal variant="h4">Relatórios</StyledTitleCharcoal>
      <ResponsiveContainer className="pt-4 pb-4" width="100%" height={500}>
        <StyledBarChart height={500} width={width} data={listOfGraphs}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis name="Mês" dataKey="mesReferencia" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar barSize={100} name="Número de caixas Agendadas" dataKey="volumesAgendados" fill="#8884d8" />
          <Bar barSize={100} name="Número de caixas Entregues" dataKey="volumesEntregues" fill="#82ca9d" />
        </StyledBarChart>
      </ResponsiveContainer>
      <StyledListItemIcon>
      </StyledListItemIcon>
    </StyledContainerForResponsive>
  )
}

export default GraphicReport;