import styled from "styled-components";
import { azulForte, azulFraco } from "./variaveis";
import SearchIcon from '@mui/icons-material/Search';

export const StyledSearchIcon = styled(SearchIcon)`
  color: ${azulFraco};
  font-size: 2.4rem;
  margin: 2px 0 2px 2px;
  position: absolute;
  top: 35px;
  right: 10px;
  &:hover {
    color: ${azulForte};
    cursor: pointer;
  }
`;

export const StyledSearchIconFlex = styled(SearchIcon)`
  color: ${azulFraco};
  font-size: 2.4rem;
  margin: 0 4px 0 0;
  &:hover {
    color: ${azulForte};
    cursor: pointer;
  }
`;