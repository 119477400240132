import { combineReducers } from "redux";
import agendamentos from "./agendamentos";
import { default as auth, default as users } from "./auth";
import credentials from "./credentials";
import relatorios from "./relatorios";
import preAgendamentos from "./preAgendamento";

export default combineReducers({
  agendamentos,
  relatorios,
  users,
  credentials,
  auth,
  preAgendamentos
});
