import axios from "axios";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApi } from "../../hooks/useApi";
import { User } from "../../types/User";
import { AuthContext } from "./AuthContext";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const api = useApi();

  useEffect(() => {
    const validateToken = () => {
      const storageData = localStorage.getItem("authToken");
      if (storageData) {
        return true;
      }
    };
    validateToken();
  }, [api]);

  useEffect(() => {}, [user]);

  const signin = async (username: string, password: string) => {
    setLoading(true);
    clearTokenLocalStorage();

    try {
      const data = await api.signin(username, password);
      const tokenEncoded = data.access_token;
      let tokenDecoded: any = jwt_decode(tokenEncoded);
      const userTk = tokenDecoded.email;
      const tk = data.access_token;
      const cnpj = tokenDecoded.cnpj;
      if (userTk && tk) {
        axios.defaults.headers.common = { Authorization: `Bearer ${tk}` };
        setUser(userTk);
        setTokenLocalStorage(tk);
        localStorage.setItem("cnpj", cnpj);
        toast.success("Login realizado com sucesso!");

        setLoading(false);
        return true;
      }
      throw new Error("Cliente com problemas cadastrais.");
    } catch (error) {
      setUser(null);
      setLoading(false);
      throw new Error("E-mail ou senha inválidos.");
    }
  };

  const signout = async () => {
    setUser(null);
    clearTokenLocalStorage();
  };

  const setTokenLocalStorage = (token: string) => {
    localStorage.setItem("authToken", token);
  };

  const clearTokenLocalStorage = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("admin");
    localStorage.removeItem("cnpj");
  };

  return (
    <AuthContext.Provider value={{ user, signin, signout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
