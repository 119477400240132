import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import PortraitIcon from '@mui/icons-material/Portrait';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ElementType, lazy } from 'react';
import UserRegister from '../components/UserRegister/UserRegister';
import TableAgendamentos from '../components/_Agendamentos/Tables/TableAgendamentos';
import TablePreAgendamentos from '../components/_PreAgendamento/TablePreAgendamentos';
import { Relatorios } from '../pages/Relatorios';
import { getCurrentUserValues } from '../utils/auth';
import { isAdminEmail } from '../service/admin';

const PreAgendamentoForm = lazy(() => import('../components/_PreAgendamento/PreAgendamentoForm'));
const AgendamentoForm = lazy(() => import("../components/_Agendamentos/AgendamentoForm"));

interface IRoute {
  path: string;
  name: string;
  exact: boolean;
  component: ElementType;
  isPrivate?: boolean;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  hidden?: boolean;
}

const buildRouresByRole = (isAdmin: boolean) => {
  const decodedToken = getCurrentUserValues();
  const { prioritario } = decodedToken;
  const PrioritarioRoutes: IRoute[] = [
    {
      path: "/pre-agendamento",
      name: "Pré-Agendamento",
      exact: true,
      component: PreAgendamentoForm,
      isPrivate: false,
      icon: MoreTimeIcon
    },
    {
      path: "/pre-agendamentos",
      name: "Lista de Pré-agendamentos",
      exact: true,
      component: TablePreAgendamentos,
      isPrivate: false,
      icon: ContentPasteSearchIcon
    }
  ];

  const MenuRoutes: IRoute[] = [
    {
      path: "/cadastro",
      name: "Cadastro usuário",
      exact: true,
      component: UserRegister,
      isPrivate: true,
      icon: PortraitIcon,
      hidden: !isAdmin
    },
    {
      path: "/relatorios",
      name: "Relatórios",
      exact: true,
      component: Relatorios,
      isPrivate: true,
      icon: AssessmentIcon
    },
    {
      path: "/agendamento",
      name: "Agendar horário",
      exact: true,
      component: AgendamentoForm,
      isPrivate: true,
      icon: AlarmAddIcon
    },
    {
      path: "/agendamentos",
      name: "Horários agendados",
      exact: true,
      component: TableAgendamentos,
      isPrivate: true,
      icon: CalendarMonthIcon
    },
    ...prioritario === "true" || isAdminEmail(decodedToken?.email) ? PrioritarioRoutes : []
  ];

  return MenuRoutes
};

export default buildRouresByRole;