import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IsMobileContext } from "../../contexts/IsMobile/IsMobileContext";
import { azulForte } from "../UI/variaveis";

interface StyledSidebarProps {
  hidden?: boolean;
  ismobile?: string;
  open?: boolean;
}

interface SidebarProps {
  open?: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  routes?: any[];
  user?: any;
}

interface StyledListItemButtonProps {
  open?: boolean;
}

interface StyledListItemIconProps {
  open?: boolean;
  selected?: boolean;
}

const sidebarWidth = 290;

const openedMixin = (theme: Theme): CSSObject => ({
  width: sidebarWidth,
  backgroundColor: "#f3f3f3",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
    delay: 400
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#f3f3f3",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledSidebarHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const StyledSidebar = styled(MuiDrawer)<StyledSidebarProps>(
  ({ theme, ismobile, open }) => ({
    width: sidebarWidth,
    zIndex: 11,
    flexShrink: 0,
    position: "fixed",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),

    //IsMobile
    ...(ismobile === 'true' &&
      open && {
      position: "relative",
      width: "100%",
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: "100%",
        boxSizing: "border-box",
      },
    }),
  })
);

const StyledListItemButton = styled(ListItemButton)<StyledListItemButtonProps>(
  ({ open }) => ({
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    ":hover": {
      "& .MuiListItemIcon-root": {
        color: "#28B9DA",
      },
    },
  })
);

export const StyledListItemIcon = styled(ListItemIcon)<StyledListItemIconProps>(
  ({ open, selected }) => ({
    minWidth: 0,
    marginRight: open ? "1em" : "auto",
    justifyContent: "center",
    ...(selected && {
      color: azulForte,
    }),
  })
);

const LinkMenu = styled(Link)(() => ({
  color: "inherit",
  textDecoration: "none",
}));

export default function Sidebar(props: Readonly<SidebarProps>) {
  const { open, handleDrawerClose, handleDrawerOpen, routes, user } =
    props;
  const theme = useTheme();
  const { isMobile } = useContext(IsMobileContext);

  const [path, setPath] = useState("");
  let location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location, setPath]);

  const activedRoute = (route: any) => {
    return route === path;
  };

  return (
    <StyledSidebar
      className="sidebar"
      variant={isMobile ? "persistent" : "permanent"}
      ismobile={isMobile?.toString()}
      open={open}
      onMouseOver={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      id="sidebarMenu"
    >
      <StyledSidebarHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (<ChevronLeftIcon />) : (<ChevronRightIcon />)}
        </IconButton>
      </StyledSidebarHeader>
      <List>

        {routes?.map((route, index) => (
          <LinkMenu to={route.path} key={`link-menu-sidebar-${index}`}>

            <StyledListItemButton
              open={open}
              selected={activedRoute(route.path)}
              id={route.id}
              hidden={route.hidden}
            >
              <StyledListItemIcon
                open={open}
                selected={activedRoute(route.path)}
              >
                <route.icon fontSize={"large"} />
              </StyledListItemIcon>
              <ListItemText
                primary={route.name}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </StyledListItemButton>
          </LinkMenu>
        ))}
      </List>
      {/* <Divider /> */}
    </StyledSidebar>
  );
}
