import { IMetrica, IRelatorio } from "../../../types/Agendamentos/relatorio";

export enum RelatoriosStoreTypes {
    GET_RELATORIOS = "@relatorios/GET_RELATORIOS",
    LOAD_RELATORIOS = "@relatorios/LOAD_RELATORIOS",
    LOADING_FAILURE = "@relatorios/LOADING_FAILURE",
  }
  
  export interface RelatorioState {
    readonly relatorios: IRelatorio[];
    readonly metricas: IMetrica[];
    readonly loading: boolean;
  }