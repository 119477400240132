import styled from "styled-components";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from "@mui/material";
import { azulForte, azulForteEscuro, vermelhoForte, vermelhoFraco } from "./variaveis";

export const StyledButtonBlue = styled(Button)`
background-color: ${azulForte};
color: white;
font-weight: bold;
width: max-content;

&:hover {
    background-color: ${azulForteEscuro};
    pointer: pointer;
    transition: 0.3s;
}

& * {
    margin: 5px 0 2px 0;
    padding: 0;
}
`;

export const StyledArrowBackIcon = styled(ArrowBackIcon)`
    color: ${azulForte};
    margin: 0 4px 6px 0;
    font-size: 2.5rem;
    cursor: pointer;
`;

export const StyledButton = styled(Button)`
height: 2.5rem;
    max-width: max-content;
    padding: 0 15px;
    & h2 {
        display: flex;
        padding: 6px 0 0 0;
        align-items: center;
    }
`;

export const StyledButtonSubmit = styled.button`
    background-color: ${azulForte};
    border: none;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;

    &:hover {
        cursor: pointer;
        background-color: ${azulForteEscuro};
        transition: 0.3s;
    }
`;


export const StyledGroupButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    & * {
        font-weight: 600;
    };
`;

export const StyledRedButton = styled(StyledButtonBlue)`
    background-color: ${vermelhoFraco};
    &:hover {
        background-color: ${vermelhoForte};
    }
`;