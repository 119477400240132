import React from 'react';
import { StyledAdvancedSearch } from './styles';

interface AdvancedSearchProps {
  childrens: React.ReactNode[];
}

const AdvancedSearch: React.FC<AdvancedSearchProps> = ({
  childrens
}) => {
  return (
    <StyledAdvancedSearch>
      {childrens?.map((children, index) => {
        const isElement = React.isValidElement(children);
        const key = `children-search-${index}`;
        if (!isElement) return null;
        return React.cloneElement(children, {
          ...children.props,
          key: key,
        });
      })}
    </StyledAdvancedSearch>
  );
};


export default AdvancedSearch;