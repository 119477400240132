import { Reducer } from "redux";
import { AuthState, AuthStoreTypes } from "./types";

const INITIAL_STATE = {
    loading: false
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case AuthStoreTypes.LOADING_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }

        case AuthStoreTypes.LOADING_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action?.payload
            };
        }

        case AuthStoreTypes.SIGNIN: {
            return {
                ...state,
                loading: true
            };
        }

        case AuthStoreTypes.LOGOUT: {
            return {
                ...state,
                loading: true
            };
        }

        default: return state;
    }
};

export default reducer;