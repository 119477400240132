import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { AgendamentoProvider } from "./contexts/Agendamento/AgendamentoProvider";
import { AuthProvider } from "./contexts/Auth/AuthProvider";
import { IsMobileProvider } from "./contexts/IsMobile/IsMobileProvider";
import { PreAgendamentoProvider } from "./contexts/PreAgendamento/PreAgendamentoProvider";
import "./index.css";
import store from "./store/index";

ReactDOM.render(
  <React.StrictMode>
    <IsMobileProvider>
      <AuthProvider>
        <Provider store={store}>
          <AgendamentoProvider>
            <PreAgendamentoProvider>
              <App />
            </PreAgendamentoProvider>
          </AgendamentoProvider>
        </Provider>
      </AuthProvider>
    </IsMobileProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
