import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { StyledTitleCharcoal } from '../UI/titles';
import { StyledDialogActions } from './styles';
import { vermelhoForte, vermelhoFraco } from '../UI/variaveis';
import { grey, red } from '@mui/material/colors';

const ModalTypes = {
  confirm: 'confirm',
  alert: 'alert'
};

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  type?: typeof ModalTypes.alert
}

const GenericModal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  message,
  onConfirm,
  onCancel,
  type,
}) => {
  const isAlertModal = Boolean(type === ModalTypes.alert)
  const handleClose = () => {
    onClose();
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    onClose();
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Dialog sx={{
      '& .MuiDialog-paper': {
        width: '100%',
        minHeight: '230px',
        maxWidth: '750px',
        borderRadius: '10px',
        padding: '30px',
      }
    }} open={open} onClose={handleClose}>
      <StyledTitleCharcoal variant="h4" component="div">
        {title}
      </StyledTitleCharcoal>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '1.4rem' }}>{message}</DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        {onConfirm && (
          <Button
            sx={{
              padding: '14px 30px 8px',
              fontSize: '1.1rem',
              backgroundColor: isAlertModal ? red[600] : grey[600],
              "&:hover": {
                backgroundColor: isAlertModal ? red[700] : grey[700],
              }
            }}
            variant='contained'
            onClick={handleConfirm}
          >
            Sim
          </Button>
        )}
        {onConfirm && <Button
          sx={{ padding: '14px 30px 8px', fontSize: '1.1rem', }}
          variant='contained' onClick={handleClose} color="primary">
          Não
        </Button>}
      </StyledDialogActions>
    </Dialog>
  );
};

export default GenericModal;