import { toast } from "react-toastify";
import { ResponseError } from "./types";

export const listErrorMessagesFromRequest = (data: any) => {
  if (data?.response?.data?.errors?.length) {
    return data?.response?.data?.errors?.map((error: any) => error);
  }
}

export const notifyErrorStatus = (data: any) => {
  const dataErrors = listErrorMessagesFromRequest(data);
  if (dataErrors?.length) {
    dataErrors.forEach(async (error: ResponseError) => {
      toast.error(error.message, { autoClose: 8000 })
    });
  }
  return;
}

export const onErrorCatch = (error: ResponseError, action: any) => {
  const { onError } = action;
  notifyErrorStatus(error);
  onError && onError(error);
}