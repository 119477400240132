import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GrSearch } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import * as yup from "yup";
import api from "../../hooks/api";
import { StyledTitleCharcoal } from "../UI/titles";
import "./UserRegister.css";

export const useStyles = makeStyles((theme) => ({
  TextField: {
    border: "1px solid #E0E0E0",
    borderRadius: "6px",
  },
}));

export const StyledFieldStyled = styled(TextField)`
  margin: 0px !important;
`;

const normalizePhoneNumber = (value: string) => {
  if (!value) return "";

  return value
    .replace(/[\D]/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)/, "$1");
};

const normalizeCnpjNumber = (value: string) => {
  if (!value) return "";

  return value
    .replace(/[\D]/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})(\d+?)/, "$1");
};

interface IUserRegister {
  cnpj: string;
  firstName: string;
  email: string;
  phone: string;
  adm: string;
  type: string;
  priority: boolean;
  level: number;
}

const schema = yup
  .object({
    cnpj: yup.string().required().min(18, "CNPJ inválido"),
    firstName: yup.string()
      .required("Nome obrigatório")
      .max(60, "Nome deve conter no máximo 60 caracteres"),
    email: yup.string(),
    phone: yup
      .string()
      .required("Telefone obrigatório")
      .min(15, "Telefone inválido"),
  })
  .required();

const UserRegister = () => {
  const [cnpj, setCnpj] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [adm, setAdm] = useState("0");
  const [type, setType] = useState("0");
  const [priority, setPriority] = useState(false);
  const [level, setLevel] = useState(0);
  const [dataUser, setDataUser] = useState<IUserRegister>();
  const [btnDisable, setBtnDisable] = useState(false);

  const isValid = priority === true;
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("admin")) navigate("/agendamentos");
  }, []);


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserRegister>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((e: any) => {
    if (dataUser) {
      api
        .put<IUserRegister>(`/usuarios?username=${dataUser.email}`, {
          username: email,
          firstName: firstName,
          lastName: firstName,
          enabled: true,
          emailVerified: true,
          email: email,
          attributes: {
            categoria: type,
            nivelUsuario: level,
            prioritario: priority,
            fone: phone?.replace(/[\D]/g, ""),
            cnpj: cnpj?.replace(/[\D]/g, ""),
          },
        })
        .then(() => {
          toast.success("Usuário alterado com sucesso!");
          setCnpj("");
          setFirstName("");
          setEmail("");
          setPhone("");
          setAdm("");
          setType("");
          setPriority(false);
          setLevel(0);
          setDataUser(undefined);
        });
    } else {
      const dados = {
        username: email,
        firstName: firstName,
        lastName: firstName,
        enabled: true,
        emailVerified: true,
        email: email,
        attributes: {
          categoria: type,
          nivelUsuario: level,
          prioritario: priority,
          fone: phone?.replace(/[\D]/g, ""),
          cnpj: cnpj?.replace(/[\D]/g, ""),
        },
        credentials: {
          temporaria: false,
          senha: "teste123",
        },
      };
      api
        .post("/usuarios", dados)
        .then((response) => {
          const papeis = { username: email, tipoUsuario: adm };
          api.post("/papeis", papeis);
          toast.success("Usuário cadastrado com sucesso!");
          setCnpj("");
          setFirstName("");
          setEmail("");
          setPhone("");
          setAdm("");
          setType("");
          setPriority(false);
          setLevel(0);
          setDataUser(undefined);
          setBtnDisable(true);
        })
        .catch((error) => {
          toast.error("Erro ao cadastrar usuário");
        });
      setBtnDisable(false);
    }
  });

  const handleInputChange = async (e: any) => {
    const { value } = e.target;
    if (!value) return;

    const url = `/usuarios?username=${value}`;
    await api
      .get(url)
      .then((response) => {
        const data = response.data;
        setDataUser(data);
        setType(data.attributes.categoria);
        setCnpj(normalizeCnpjNumber(data.attributes.cnpj));
        setPhone(normalizePhoneNumber(data.attributes.fone));
        setAdm(data.attributes.nivelUsuario);
        setPriority(data.attributes.prioritario);
        setFirstName(data.firstName);
      })
      .catch((error) => {console.log(error)});
  };

  return (
    <>
      <ToastContainer autoClose={3000} position={toast.POSITION.TOP_RIGHT} />
      <Box className="container">
        <form
          onSubmit={onSubmit}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            item={true}
            lg={6}
            md={8}
            sm={10}
            xs={12}
            spacing={4}
            className="grid-form"
            style={{
              marginLeft: "12%",
            }}
          >
            <StyledTitleCharcoal
              style={{ marginBottom: "30px", paddingLeft: "30px" }}
              variant="h4"
              noWrap
              component="div"
            >
              Cadastro usuário
            </StyledTitleCharcoal>
            {/* <p>Olá {auth.user}, tudo bem?</p> */}
            <Grid item={true} xs={12}>
              <FormControl fullWidth>
                <StyledTitleCharcoal
                  style={{ marginBottom: "10px" }}
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1 }}
                >
                  E-mail
                </StyledTitleCharcoal>
                <StyledFieldStyled
                  {...register("email")}
                  className={classes.TextField}
                  placeholder="Busque um usuário ou crie um novo"
                  id="email"
                  name="email"
                  value={email}
                  type="email"
                  margin="normal"
                  onBlur={handleInputChange}
                  onKeyPress={(e: any) =>
                    e.key === "Enter" ?? handleInputChange(e)
                  }
                  InputProps={{
                    endAdornment: (
                      <GrSearch onClick={(e: any) => handleInputChange(e)} />
                    ),
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  size="small"
                  required
                />
                {errors.email?.message && (
                  <StyledTitleCharcoal variant="caption" color="error">
                    {errors.email?.message}
                  </StyledTitleCharcoal>
                )}
              </FormControl>
            </Grid>

            <Grid item={true} xs={6}>
              <StyledTitleCharcoal
                style={{ marginBottom: "10px" }}
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                CNPJ
              </StyledTitleCharcoal>
              <FormControl fullWidth>
                <StyledFieldStyled
                  {...register("cnpj")}
                  className={classes.TextField}
                  placeholder="CNPJ"
                  id="cnpj"
                  name="cnpj"
                  value={cnpj}
                  onChange={(e) => setCnpj(normalizeCnpjNumber(e.target.value))}
                  fullWidth
                  type="text"
                  margin="normal"
                  required
                  size="small"
                />
                {errors?.cnpj && (
                  <StyledTitleCharcoal variant="caption" color="error">
                    {errors.cnpj?.message}
                  </StyledTitleCharcoal>
                )}
              </FormControl>
            </Grid>
            <Grid item={true} xs={6}>
              <StyledTitleCharcoal
                style={{ marginBottom: "10px" }}
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Fornecedor / Transportador
              </StyledTitleCharcoal>
              <FormControl fullWidth>
                <StyledFieldStyled
                  {...register("firstName")}
                  className={classes.TextField}
                  placeholder="nome"
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  type="text"
                  margin="normal"
                  required
                  size="small"
                />
                {errors.firstName && (
                  <StyledTitleCharcoal variant="caption" color="error">
                    {errors.firstName?.message}
                  </StyledTitleCharcoal>
                )}
              </FormControl>
            </Grid>
            <Grid item={true} xs={6}>
              <StyledTitleCharcoal
                style={{ marginBottom: "10px" }}
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Telefone
              </StyledTitleCharcoal>
              <FormControl fullWidth>
                <StyledFieldStyled
                  {...register("phone", { min: 11 })}
                  className={classes.TextField}
                  placeholder="telefone"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) =>
                    setPhone(normalizePhoneNumber(e.target.value))
                  }
                  fullWidth
                  type="string"
                  required
                  size="small"
                />
                {errors.phone && (
                  <StyledTitleCharcoal variant="caption" color="error">
                    {errors.phone?.message}
                  </StyledTitleCharcoal>
                )}
              </FormControl>
            </Grid>

            <Grid item={true} xs={6}>
              <StyledTitleCharcoal
                style={{ marginBottom: "10px" }}
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Usuário
              </StyledTitleCharcoal>
              <FormControl fullWidth>
                <StyledFieldStyled
                  {...register("adm")}
                  id="adm"
                  className={classes.TextField}
                  name="adm"
                  select
                  value={adm}
                  onChange={(e) => setAdm(e.target.value)}
                  fullWidth
                  required
                  size="small"
                >
                  <MenuItem id="0" value="0" disabled>
                    Selecione
                  </MenuItem>
                  <MenuItem id="admin" value="ADMIN">
                    Administrador
                  </MenuItem>
                  <MenuItem id="user" value="USER">
                    Usuário
                  </MenuItem>
                </StyledFieldStyled>
                {errors.adm && (
                  <StyledTitleCharcoal variant="caption" color="error">
                    {errors.adm?.message}
                  </StyledTitleCharcoal>
                )}
              </FormControl>
            </Grid>
            <Grid item={true} xs={4}>
              <StyledTitleCharcoal
                style={{ marginBottom: "10px" }}
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Tipo
              </StyledTitleCharcoal>
              <FormControl fullWidth>
                <StyledFieldStyled
                  {...register("type")}
                  id="type"
                  className={classes.TextField}
                  name="type"
                  select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  variant="outlined"
                  fullWidth
                  required
                  size="small"
                >
                  <MenuItem id="0" value="0" disabled>
                    Selecione
                  </MenuItem>
                  <MenuItem id="fornecedor" value="FORNECEDOR">
                    Fornecedor
                  </MenuItem>
                  <MenuItem id="transportadora" value="TRANSPORTADORA">
                    Transportador
                  </MenuItem>
                </StyledFieldStyled>
                {errors.type && (
                  <StyledTitleCharcoal variant="caption" color="error">
                    {errors.type?.message}
                  </StyledTitleCharcoal>
                )}
              </FormControl>
            </Grid>
            <Grid item={true} xs={4}>
              <StyledTitleCharcoal
                style={{ marginBottom: "10px" }}
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Prioritário
              </StyledTitleCharcoal>
              <FormControl fullWidth>
                <StyledFieldStyled
                  {...register("priority", { required: true })}
                  id="priority"
                  className={classes.TextField}
                  name="priority"
                  select
                  value={priority}
                  onChange={(e: any) => setPriority(e.target.value)}
                  variant="outlined"
                  fullWidth
                  required
                  size="small"
                >
                  <MenuItem id="sim" value={true as any}>
                    Sim
                  </MenuItem>
                  <MenuItem id="nao" value={false as any}>
                    Não
                  </MenuItem>
                </StyledFieldStyled>
                {errors.priority && (
                  <StyledTitleCharcoal variant="caption" color="error">
                    {errors.priority?.message}
                  </StyledTitleCharcoal>
                )}
              </FormControl>
            </Grid>

            <Grid item={true} xs={4}>
              <StyledTitleCharcoal
                style={{ marginBottom: "10px" }}
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Nível
              </StyledTitleCharcoal>
              <FormControl fullWidth disabled>
                <StyledFieldStyled
                  id="level"
                  className={classes.TextField}
                  name="level"
                  disabled={!isValid}
                  select
                  value={level}
                  onChange={(e: any) => setLevel(e.target.value)}
                  variant="outlined"
                  fullWidth
                  required
                  size="small"
                  placeholder="nível"
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem id="1" value={1}>
                    1
                  </MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3 </MenuItem>
                </StyledFieldStyled>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            className="grid-container-hidden"
            container
            item={true}
            display={{ xs: "none", lg: "block" }}
            lg={6}
            md={4}
            sm={2}
          ></Grid>
          <Box className="bottom">
            <Button
              id="submit"
              style={{
                position: "absolute",
                bottom: "10px",
                right: "20px",
                backgroundColor: "#28B9DA",
                color: "#fff",
                width: "96px",
                height: "48px",
              }}
              type="submit"
            >
              Salvar
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};
export default UserRegister;
