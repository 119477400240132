import { call, put } from "redux-saga/effects";
import { getPreAgendamentos, postConfirmaPreAgendamento, postPreAgendamento, putDataPreAgendamento, putStatusPreAgendamento } from "../../../service/preSchedule.service";
import { PreAgendamentoStatusRequest } from "../../../types/PreAgendamentos/preAgendamento";
import { toSortedPreAgendamentos } from "../../../utils/lists";
import { notifyErrorStatus } from "../service";
import { PreAgendamentosStoreTypes } from "./types";

export function* postPreAgendamentoSaga(action: any): Generator<any, void, any> {
  try {
    const { payload } = action;
    const res = yield call(postPreAgendamento, payload);
    yield put({ type: PreAgendamentosStoreTypes.LOADING_SUCCESS })
    action?.onSuccess?.(res?.data);
  } catch (error: any) {
    yield put({ type: PreAgendamentosStoreTypes.LOADING_FAILURE })
    notifyErrorStatus(error);
    action?.onError?.(error?.response);
  }
}

export function* getPreAgendamentosSaga(): Generator<any, void, any> {
  try {
    const res = yield call(getPreAgendamentos);
    yield put({ type: PreAgendamentosStoreTypes.LOADING_SUCCESS, payload: { preAgendamentosList: toSortedPreAgendamentos(res?.data) } });
  } catch (error: any) {
    yield put({ type: PreAgendamentosStoreTypes.LOADING_FAILURE })
    notifyErrorStatus(error);
  }
}

export function* putStatusPreAgendamentoSaga(action: any): any {
  try {
    const payload: PreAgendamentoStatusRequest = action?.payload;
    const res = yield call(putStatusPreAgendamento, payload);
    yield put({ type: PreAgendamentosStoreTypes.LOADING_SUCCESS })
    action?.onSuccess?.(res?.data);
  } catch (error: any) {
    yield put({ type: PreAgendamentosStoreTypes.LOADING_FAILURE })
    notifyErrorStatus(error);
    action?.onError?.(error?.response);
  }
}

export function* putNewDatePreAgendamentoSaga(action: any): any {
  try {
    const { payload } = action;
    const res = yield call(putDataPreAgendamento, payload);
    yield put({ type: PreAgendamentosStoreTypes.LOADING_SUCCESS })
    action?.onSuccess?.(res?.data);
  } catch (error: any) {
    yield put({ type: PreAgendamentosStoreTypes.LOADING_FAILURE })
    notifyErrorStatus(error);
    action?.onError?.(error?.response);
  }
}

export function* postConfirmaPreAgendamentoSaga(action: any): any {
  try {
    const { payload } = action;
    const res = yield call(postConfirmaPreAgendamento, payload);
    yield put({ type: PreAgendamentosStoreTypes.LOADING_SUCCESS })
    action?.onSuccess?.(res?.data);
  } catch (error: any) {
    yield put({ type: PreAgendamentosStoreTypes.LOADING_FAILURE })
    notifyErrorStatus(error);
    action?.onError?.(error?.response);
  }
}