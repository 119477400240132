import { Reducer } from "redux";
import { IMetrica, IRelatorio } from "../../../types/Agendamentos/relatorio";
import { RelatorioState, RelatoriosStoreTypes } from "./types";

const INITIAL_STATE = {
    relatorios: [] as IRelatorio[],
    metricas: [] as IMetrica[],
    loading: false,
};

const reducer: Reducer<RelatorioState> = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case RelatoriosStoreTypes.GET_RELATORIOS: {
            return {
                ...state,
                loading: true,
            };
        };

        case RelatoriosStoreTypes.LOAD_RELATORIOS: {
            return {
                ...state,
                loading: false,
                relatorios: action?.payload?.tickets,
                metricas: action?.payload?.metricasAgendamento,
            };
        }

        case RelatoriosStoreTypes.LOADING_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }

        default: state;
    }

    return state;
};

export default reducer;