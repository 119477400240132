import styled from "styled-components";
import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Modal, Select } from "@mui/material";
import { blue, grey, red, yellow } from "@mui/material/colors";
import { azulForte, azulForteEscuro } from "../../../UI/variaveis";

export const StyledTableDeAgendamentos = styled.div((props) => ({
    width: "100%",
    minWidth: "925px",
    borderRadius: "10px",
    overflow: "hidden",
    border: "solid 0.1px #3f3f3f33",

    "& .print-button": {
        color: blue[500],
    },
    "& .edit-button": {
        color: yellow[900],
    },
    "& .delete-button": {
        color: red[700],
    },


    "& .ticket-row:hover": {
        color: "#fff",
        backgroundColor: grey[300],

        "& .print-button:hover": {
            color: azulForteEscuro,
        },
        "& .edit-button:hover": {
            color: azulForteEscuro,
        },
        "& .delete-button:hover": {
            color: azulForteEscuro,
        }
    },
}));

export const StyledTableRow = styled.div((props) => ({
    width: "100%",
    display: "flex",
    minHeight: "51px",
    textAlign: "center",
    alignItems: "center",
}));

export const StyledTableColumn = styled.div((props) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
}));

export const StyledGrid = styled(Grid)((props) => ({
    padding: "8px",
    verticalAlign: "middle",
    dislplay: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    whiteSpace: "nowrap",
    margin: "0 !important",
    borderRight: "solid 0.1px #3f3f3f33",
    fontSize: "1.2rem",

    "& > svg": {
        width: "36px",
        height: "36px",
    },


    "& > *": {
        height: "100%",
        fontSize: "2rem",
        cursor: "pointer",
    },

    "&.header-item": {
        border: "none !important",
        padding: "14px 10px 10px",
        fontSize: '1.3rem',
    },

    "&.control-table": {
        display: "flex",
        justifyContent: "space-between",
        minWidth: "120px",

        "& > *": {
            maxHeight: "40px",
        }
    },

    "&:first-of-type": {
        maxWidth: "auto",
        height: "100%",
    },

    "&:last-of-type": {
        width: "30px !important",
        borderRight: "none !important",
    }

}));

export const StyledAccordion = styled(Accordion)((props) => ({
    margin: "0 !important",
    padding: "0 !important",
    width: "100%",
    borderRadius: "0 !important",

    "&:hover": {
        backgroundColor: grey[300],
    },

    "& .MuiAccordionSummary-gutters.Mui-expanded": {
        borderBottom: "none !important"
    },

    "& .Mui-expanded": {
        color: "#fff",
        backgroundColor: grey[500],
        fontWeight: "bold",
    },

    "& .MuiAccordionSummary-expandIconWrapper": {
        width: "30px",
    },

    "& .MuiAccordionSummary-content": {
        margin: "0 !important",
    },

    "&.MuiAccordion-rounded": {
        "&::before": {
            display: "none"
        }
    }
}));

export const StyledAccordionSummary = styled(AccordionSummary)((props) => ({
    width: "100%",
    padding: "0 !important",
}));

export const StyledContainerGrid = styled(Grid)((props) => ({
    width: "100%",
    display: "flex",
}));

export const StyledAccordionDetails = styled(AccordionDetails)((props) => ({
    backgroundColor: grey[200],
    padding: "0 !important",
}));

export const StyledContainerForSelectInTable = styled.div((props) => ({
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",

    "& label": {
        margin: "0 10px",
    }
}));

export const StyledHeaderThead = styled.div((props) => ({
    width: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: azulForte,
    color: "#fff",
    fontWeight: "bold",
    borderRadius: "5px 5px 0 0",
    height: "65px",
    "& .header-item": {

    }
}))

export const StyledModal = styled(Modal)`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 20px;
`;

export const StyledContainerModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
`

export const StyledDivNoContent = styled.div`
    padding: 6px;
    text-align: center;
    border-bottom: solid 0.1px #3f3f3f33;
    border-radius: 5px;
`;

export const StyledSelector = styled(Select)`
    color: #fff;
    border: none !important;
    & .MuiSelect-select: {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
        backgroundImage: none !important
    };
    & .MuiOutlinedInput-notchedOutline: {
        borderColor: transparent !important
    }
    & input: {
        display: none;
    }
`;

export const StyledMenuItemFilter = styled(MenuItem)`
    display: flex;
    alignItems: center;
    justifyContent: space-between;
    width: 100%;
    padding: 0 16px 0 0;
`;

export const StyledSelectFilter = styled(Select)`
    opacity: 0;
    position: absolute;
    top: 0;
    left: -100px;
    bottom: 0;
    right: 0;
`;