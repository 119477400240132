import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { AgendamentosState } from "../../../../store/ducks/agendamentos/types";
import { INotaFiscal, ITicket, ITicketReduzido } from "../../../../types/Agendamentos/agendamento.d";
import { StyledBootstrapTable } from "../styles";

interface TableTicketComNotaProps {
    ticket: ITicketReduzido;
}

function TableTicketComNota({ ticket }: TableTicketComNotaProps) {
    const { loading, ticketsComNotas } = useSelector<ApplicationState, AgendamentosState>((state) => state?.agendamentos);
    const [agendamentoSelecionado, setAgendamentoSelecionado] = useState<ITicket>();

    useEffect(() => {
        if (ticketsComNotas?.find((agendamento: ITicket) => agendamento.ticket === ticket.ticket)) {
            setAgendamentoSelecionado(ticketsComNotas?.find((agendamento: ITicket) => agendamento.ticket === ticket.ticket));
        }
    }, [ticketsComNotas, ticket])

    const hasAgendamento = (agendamento?: ITicket) => {
        if (!agendamento) return (
            <tr id={`table-line-agendamento-no-key`} key={`table-line-keys-no-key`}><td colSpan={6}>Nenhum agendamento encontrado</td></tr>
        )
    }

    const renderNotas = (agendamento?: ITicket) => {
        hasAgendamento(agendamento)
        return (
            <tbody className="table-hover">
                {agendamento?.notas?.map((nota: INotaFiscal) => {
                    return (
                        <tr id={`table-line-agendamento-${nota.notaFiscal}`} key={`table-line-keys-${nota.notaFiscal}`}>
                            <td>{nota?.chave}</td>
                            <td>{nota?.notaFiscal}</td>
                            <td>{nota.volume ? nota.volume : 0}</td>
                            <td>{nota?.quantidadeSku}</td>
                            <td>{nota?.status}</td>
                        </tr>
                    )
                })}
            </tbody>
        )
    }

    const renderTHeader = () => {
        return (
            <thead>
                <tr>
                    <th>Chave</th>
                    <th>Nota Fiscal</th>
                    <th>Número de caixas</th>
                    <th>Quantidade Sku</th>
                    <th>Status</th>
                </tr>
            </thead>
        )
    }

    return (
        <>
            {!agendamentoSelecionado || loading ? "Carregando..." :
                <StyledBootstrapTable id='keys-table' striped hover>
                    {renderTHeader()}
                    {renderNotas(agendamentoSelecionado)}
                    {!agendamentoSelecionado?.notas?.length && (
                        <tfoot>
                            <tr>
                                <td colSpan={5}>Nenhuma nota encontrada</td>
                            </tr>
                        </tfoot>)}
                </StyledBootstrapTable>}
        </>
    );
}

export default TableTicketComNota;