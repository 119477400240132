import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Table } from "react-bootstrap";
import { azulForte, azulForteEscuro, azulFraco, cinza, laranjaFraco } from "../../UI/variaveis";
import styled from 'styled-components';

export const StyledBootstrapTable = styled(Table)`
width: 100%
text-align: center;
overflow: hidden;
margin: 0 !important;

& .accordion-table-header {
    display: flex;
}

& thead {
    background-color: ${azulForte};
    height: 47.4px;

    & th {
        vertical-align: middle;
    }

    & th:first-of-type {
        height: 100% !important;
        padding: 0 1.2rem;
        text-align: left !important;
    }
}

& * { 
    white-space: nowrap; 
    text-align: center;
}

& tbody tr:last-of-type td:first-of-type {
    max-height: 100%;
    padding: 20px;
}

& td {
    vertical-align: bottom;
    border-left: solid 0.1px #3f3f3f22;
    overflow: hidden;

    & .print-button {
        width: 100%;
        cursor: pointer;
    }
}

    & .print-button {
        cursor: pointer;
    }
}

& tbody tr td:first-of-type {
    display: flex;
    justify-content: space-around;
}

& tfoot {
    height: max-content + 2px;
    background-color: #00000020;

    td div {
        display: flex;
        width: 100%;

        label {
            margin: 2px 4px;
        }
    }
}

& thead tr {
    vertical-align: bottom;
        th {
            color: #fff;
            background-color: ${azulFraco};
            height: 30px;
            padding: 8px;
            font-weight: 600;
            font-size: 1.1rem;

            & td {
                min-height: 100%;
            }
        }
    }
}
`;

export const StyledDivPagination = styled.div`
    width: 100%;
    padding: 30px auto 0 auto;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    color: ${azulForteEscuro};

    .pagination {
        li.active > .page-link, .page-link.active {
            background-color: ${azulForte};
            border-color: ${azulForte};
            color: white;
        }
    }
`;

export const StyledTrashDeleteButton = styled(DeleteIcon)`
    cursor: pointer;
    color: ${cinza};
    font-size: 1.8rem;
    
    &:hover { 
        color: red;
        transition: 0.3s;
    }

    &.delete-checked {
        padding: 0 0 2px 0;
        color: #fff !important;

        &:hover {
            color: #fff !important;
        }
    }

    &.disabled {
        color: #ccc !important;
    }
` as typeof Button;

export const StyledEditButton = styled(EditIcon)`
    cursor: pointer;
    color: ${azulForte};
    font-size: 1.8rem;
    
    &:hover {
        color: ${laranjaFraco};
        transition: 0.3s;
    }
` as typeof Button;


export const StyledContainerForSelector = styled.div`
display: flex;
justify-content: flex-end;
background-color: ${grey[300]};

label {
    vertical-align: middle;
    margin-right: 10px;
    padding: 5px;
}

select {
    height: 40px;
}
`;

export const StyledFooterLine = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 5px 10px;
    background-color: ${grey[300]};
`;

export const StyledDivSearch = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 4px;

    & input {
        width: 240px;
    }
`;