import { ErrorMessage } from '@hookform/error-message';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import { FormControl, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, Select, TextField } from "@mui/material";
import { ptBR } from 'date-fns/locale';
import { Fragment } from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, FieldErrors } from "react-hook-form";
import { StyledDatePicker, StyledDiv, StyledMenuItem, StyledTextField } from './styles';
import { DateType, InputType, Option, PasswordType, SelectType } from "./types";

registerLocale("ptBr", ptBR)

const renderErrorMessage = (errors: FieldErrors, name: string) => {
  if (!errors) return <Fragment />;
  return <ErrorMessage errors={errors} name={name} render={
    ({ message }) => <span className="error-message">{message}</span>
  } />
}

const renderSelectOptions = (options: Option[]) => {
  return options?.map((option) => {
    return (
      <StyledMenuItem key={`option-${option.value || 'empty'}`} value={option.value}>{option.label}</StyledMenuItem>
    );
  });
};

export const FormFieldTypesEnum = {
  DATE: (values: DateType[]) => {
    return values?.map((value: DateType) => {
      return (
        <Controller
          key={`date-control-${value?.name}`}
          control={value?.control}
          name={value?.name}
          render={({ field }) => (
            <StyledDiv >
              <label>{value?.label}</label>
              <StyledDatePicker
                id={value?.name}
                title={value?.title}
                locale="ptBr"
                fixedHeight
                className={value?.className}
                disabled={value?.disabled}
                filterDate={value?.filterDate}
                placeholderText='Selecione uma data'
                dateFormat="dd/MM/yyyy"
                selected={value?.value}
                {...value?.register(value?.name)}
                onChange={(date: Date) => {
                  value.onChange(date)
                  field.onChange(date)
                }}
              />
              {renderErrorMessage(value.errors, value?.name)}
            </StyledDiv>)
          }
        />
      )
    })
  },
  INPUT: (values: InputType[]) => {
    const element = values?.map((value) => {
      return (
        <FormControl
          key={`input-control-${value?.name}`}
          id={value?.name}
        >
          <label htmlFor={value?.name}>{value?.label}</label>
          <TextField
            disabled={value?.disabled}
            id={value?.name}
            error={!!value?.errors[value?.name]}
            type={value?.type}
            placeholder={value?.placeholder}
            title={value?.placeholder}
            {...value.register(value?.name)}
            multiline={value?.multiline}
            onBlur={value?.onBlur}
            className={`bg-white ${value?.className}`}
            onKeyDown={(event: any) => value?.onKeyDown}
          />
          {value?.componentInside ? value?.componentInside : <></>}
          {renderErrorMessage(value.errors, value?.name)}
        </FormControl>
      )
    })
    return element;
  },
  SELECT: (values: SelectType[]) => {
    return values?.map((value: SelectType) => {
      return (
        <Controller
          key={`date-control-${value?.name}`}
          control={value?.control}
          name={value?.name}
          render={({ field }) => (
            <FormControl
              id={value?.name}
            >
              <label htmlFor={value?.name}>{value?.label}</label>
              <Select
                className={`${value?.className}`}
                fullWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Selecione' }}
                title={value?.title}
                variant="outlined"
                labelId={value?.label}
                error={!!value?.errors[value?.name]}
                id={value?.name}
                readOnly={value?.isReadOnly}
                disabled={value?.isReadOnly}
                value={value?.value}
                {...value.register(value?.name)}
                onChange={(event: any) => {
                  const { value: selectValue } = event.target
                  value.onChange(selectValue)
                  field.onChange(selectValue)
                }}
              >
                <StyledMenuItem value="Selecione"><em className='empty-field'>Selecione</em></StyledMenuItem>
                {renderSelectOptions(value?.options)}
              </Select>
              {renderErrorMessage(value.errors, value?.name)}
            </FormControl>)}
        />)
    })
  },
  TEXT_AREA: (values: InputType[]) => {
    return values?.map((value: InputType) => {
      return (
        <FormControl fullWidth key={`text-area-control-${value?.name}`} >
          {value?.label ? <label htmlFor={value?.name}>{value?.label} </label> : <></>}
          <StyledTextField
            id={value?.name}
            fullWidth
            className={`${value?.className}`}
            multiline
            error={!!value?.errors[value?.name]}
            rows={2}
            placeholder='Digite aqui...'
            {...value.register(value?.name)}
            variant="outlined"
          />
          {renderErrorMessage(value.errors, value?.name)}
        </FormControl>
      )
    })
  },

  INPUT_UPLOAD: (values: InputType[]) => {
    return values?.map((value: InputType) => {
      return (
        <FormControl key={value?.name} id={`file-upload-${value?.name}`} >
          <label>{value?.label} </label>
          <Input
            id={value?.name}
            datatype='*/*'
            className={value?.className}
            error={!!value?.errors[value?.name]}
            type="file"
            {...value.register(value?.name)}
            onChange={(event: any) => value?.onChange && value.onChange(event)}
          />
          {!value.empty ? <CheckIcon className={"icon-position"} /> : <></>}
          <InputLabel htmlFor={value?.name} style={{ width: '100%' }} />
          {renderErrorMessage(value.errors, value?.name)}
        </FormControl>
      )
    })
  },

  INPUT_UPLOAD_XML: (values: InputType[]) => {
    return values?.map((value: InputType) => {
      return (
        <FormControl key={value?.name} id={`file-upload-${value?.name}`} >
          <label>{value?.label} </label>
          <input
            id={value?.name}
            datatype='*/*'
            accept=".xml"
            className={`${value?.className} input-upload`}
            multiple
            type="file"
            {...value.register(value?.name)}
            onChange={(event: any) => value?.onChange && value.onChange(event)}
          />
          {!value.empty ? <CheckIcon className={"icon-position"} /> : <></>}
          <InputLabel htmlFor={value?.name} style={{ width: '100%' }} />
          {renderErrorMessage(value.errors, value?.name)}
        </FormControl>
      )
    })
  },
  INPUT_PASSWORD: (values: PasswordType[]) => {
    return values?.map((value: PasswordType) => {
      return (<FormControl key={value?.name} variant="outlined">
        {value?.label ? <label htmlFor={value?.name}>{value?.label} </label> : <></>}
        <OutlinedInput
          id="standard-adornment-password"
          type={value.showPassword ? 'text' : 'password'}
          value={value.value}
          placeholder={value?.placeholder}
          {...value.register(value?.name)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={value.onClickShowPassword}
              >
                {value.showPassword ? <Visibility data-cy="show-password"
                /> : <VisibilityOff data-cy="hide-password" />}
              </IconButton>
            </InputAdornment>
          }
        />
        {renderErrorMessage(value.errors, value?.name)}
      </FormControl>)
    })
  }
}
