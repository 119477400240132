import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useRef, useState } from "react";
import buildRouresByRole from "../../Routes/MenuRoutes";
import { verifyForHidden } from "../../utils/auth";
import Sidebar from "../Sidebar/Sidebar";
import StoreContext from "../Store/Context";
import { azulForte } from "../UI/variaveis";
import "./index.css";

interface HeaderProps {
  setIsOpen: (isOpen: boolean) => void;
  open?: boolean;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface DrawerProps {
  hidden?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: 9999,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: azulForte,
  ...(open && {
    width: `calc(100% - 0px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledButton = styled(IconButton)<DrawerProps>(({ theme, hidden }) => ({
  display: `${hidden ? "none" : ""}`,
  marginRight: "2px",
}));

const Header = (prop: HeaderProps) => {
  const { removeToken } = useContext(StoreContext);
  const [open, setOpen] = useState(false);
  const { setIsOpen } = prop;
  const { user } = useContext(StoreContext);
  const HeaderRef = useRef<HTMLDivElement>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    setIsAdmin(verifyForHidden())
  }, [user]);

  const handleDrawerOpenClose = () => {
    if (typeof setOpen === "function") setOpen(!open);
    if (typeof setIsOpen === "function") setIsOpen(!open);
  };
  const handleDrawerClose = () => {
    if (typeof setOpen === "function") setOpen(false);
    if (typeof setIsOpen === "function") setIsOpen(false);
  };
  const handleDrawerOpen = () => {
    if (typeof setOpen === "function") setOpen(true);
    if (typeof setIsOpen === "function") setIsOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }} ref={HeaderRef}>
      <StyledAppBar open={open}>
        <Toolbar style={{ display: "flex" }}>
          <StyledButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => handleDrawerOpenClose()}
          >
            <MenuIcon />
          </StyledButton>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            Portal Agendamentos de Entregas
          </Typography>
          <Button
            id="logout"
            color="inherit"
            onClick={() => {
              removeToken();
              window.location.href = "/";
            }}
          >
            Sair
          </Button>
        </Toolbar>
      </StyledAppBar>
      <div className="menu-lateral">
        <Sidebar
          handleDrawerClose={() => handleDrawerClose()}
          handleDrawerOpen={() => handleDrawerOpen()}
          open={open}
          routes={buildRouresByRole(isAdmin)}
          user={user}
        ></Sidebar>
      </div>
    </Box>
  );
};

export default Header;
