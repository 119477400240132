import { call, put } from "redux-saga/effects";
import {
  deleteTicketByCode,
  getDatasDeAgendamentoDisponiveis,
  getListOfSchedules,
  getTicketByTicketNumber,
  getTransportDetails,
  postAgendamento,
  putAgendamento,
  verifyKeys
} from "../../../service/schedule.service";
import { onErrorCatch } from "../service";
import { AgendamentosStoreTypes } from "./types";

export function* getAgendamentosSaga(action: any): any {
  try {
    const res = yield call(getListOfSchedules);
    yield put({ type: AgendamentosStoreTypes.LOAD_AGENDAMENTOS, payload: res?.data })
    const { onSuccess } = action
    onSuccess?.(res?.data);
  } catch (error: any) {
    yield put({ type: AgendamentosStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
  }
}

export function* getTransportDetailsSaga(action: any): any {
  try {
    const res = yield call(getTransportDetails, action)
    yield put({ type: AgendamentosStoreTypes.LOADING_SUCCESS })
    const { onSuccess } = action
    onSuccess?.(res?.data?.[0]);
  } catch (error: any) {
    yield put({ type: AgendamentosStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
  }
}

export function* postAgendamentoSaga(action: any): any {
  try {
    const res = yield call(postAgendamento, action);
    const listUpdated = yield call(getListOfSchedules);
    yield put({ type: AgendamentosStoreTypes.LOAD_AGENDAMENTOS, payload: listUpdated?.data })
    const { onSuccess } = action
    onSuccess?.(res?.data);
  } catch (error: any) {
    yield put({ type: AgendamentosStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
  }
}

export function* putAgendamentoSaga(action: any): any {
  try {
    const { onSuccess } = action
    const res = yield call(putAgendamento, action);
    const listUpdated = yield call(getListOfSchedules);
    yield put({ type: AgendamentosStoreTypes.LOAD_AGENDAMENTOS, payload: listUpdated?.data })
    onSuccess?.(res?.data);
  } catch (error: any) {
    yield put({ type: AgendamentosStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
  }
}

export function* validateKeys(action: any): any {
  try {
    const res = yield verifyKeys(action);
    yield put({ type: AgendamentosStoreTypes.LOADING_SUCCESS })
    const { onSuccess } = action
    onSuccess?.(res?.data);
  } catch (error: any) {
    yield put({ type: AgendamentosStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
  }
}

export function* getTicketByTicketNumberSaga(action: any): any {
  try {
    const { ticket } = action?.payload;
    const res = yield getTicketByTicketNumber(ticket);
    yield put({ type: AgendamentosStoreTypes.LOAD_TICKET_COM_NOTA, payload: res.data })
    const { onSuccess } = action
    onSuccess?.(res?.data);
  } catch (error: any) {
    yield put({ type: AgendamentosStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
  }
}

export function* getDatasDisponiveisParaEntrega(action: any): any {
  try {
    const response = yield getDatasDeAgendamentoDisponiveis(action);
    yield put({ type: AgendamentosStoreTypes.LOADING_SUCCESS })
    const { onSuccess } = action
    onSuccess?.(response?.data);
  } catch (error: any) {
    const { onError } = action
    yield put({ type: AgendamentosStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
    onError && onError(error)
  }
}

export function* deleteTicketByCodeSaga(action: any): any {
  try {
    const { onSuccess, payload: { ticket } } = action
    yield deleteTicketByCode(ticket);
    onSuccess?.();
    const listUpdated = yield call(getListOfSchedules);
    yield put({ type: AgendamentosStoreTypes.LOAD_AGENDAMENTOS, payload: listUpdated?.data })
  } catch (error: any) {
    yield put({ type: AgendamentosStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
  }
}
