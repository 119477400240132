import styled from "styled-components";

export const StyledContainerKeys = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  gap: 10px;

  & button {
    flex: 1;
  }
`;