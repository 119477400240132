
import { INotaFiscal } from "../../../../types/Agendamentos/agendamento.d";

export const createNotaFiscalByKeyString = (value: string) => {
    if (typeof value === 'object') return value as INotaFiscal;
    else {
        const notaFiscal = value && value?.trim().substring(25, 35)
        return {
            chave: value,
            notaFiscal,
            motivoBloqueio: value?.length === 44 ? "NAO_VERIFICADA" : "CHAVE_INVALIDA_CHAR",
            quantidadeSku: 0,
            volume: 0,
        } as unknown as INotaFiscal;
    }
}
