import { ITicket, ITicketReduzido } from "../../../types/Agendamentos/agendamento.d";

export enum AgendamentosStoreTypes {
  GET_AGENDAMENTOS = "@agendamentos/GET_AGENDAMENTOS",
  GET_DATAS_DISPONIVEIS = "@agendamentos/GET_DATAS_DISPONIVEIS",
  GET_TRANSPORT_DETAILS = "@agendamentos/GET_TRANSPORT_DETAILS",
  GET_VERIFY_KEYS = "@agendamentos/GET_VERIFY_KEYS",
  GET_TICKET_BY_CODE = "@agendamentos/GET_TICKET_BY_CODE",
  POST_AGENDAMENTO = "@agendamentos/POST_AGENDAMENTO",
  PUT_AGENDAMENTO = "@agendamentos/PUT_AGENDAMENTO",
  LOAD_AGENDAMENTOS = "@agendamentos/LOAD_AGENDAMENTOS",
  LOAD_TICKET_COM_NOTA = "@agendamentos/LOAD_TICKET_COM_NOTA",
  LOADING_SUCCESS = "@agendamentos/LOADING_SUCCESS",
  LOADING_FAILURE = "@agendamentos/LOADING_FAILURE",
  DELETE_TICKET_BY_CODE = "@agendamentos/DELETE_TICKET_BY_CODE",
}

export interface AgendamentosState {
  readonly agendamentos: ITicketReduzido[];
  readonly ticketsComNotas: ITicket[];
  readonly loading: boolean;
  readonly loadingData: boolean;
}
