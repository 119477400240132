import { applyMiddleware, createStore, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { AgendamentosState } from "./ducks/agendamentos/types";
import { AuthState } from "./ducks/auth/types";
import { CredentialsState } from "./ducks/credentials/types";
import { RelatorioState } from "./ducks/relatorios/types";
import rootReducer from "./ducks/rootReducer";
import rootSaga from "./ducks/rootSaga";
import { PreAgendamentosState } from "./ducks/preAgendamento/types";

export interface ApplicationState {
  agendamentos: AgendamentosState;
  relatorios: RelatorioState;
  auth: AuthState;
  credentials: CredentialsState;
  preAgendamentos: PreAgendamentosState;
}

const sagaMiddleware = createSagaMiddleware();

const store: Store<ApplicationState> = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

export default store;
