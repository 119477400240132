import api from "./apiTs";

interface INewPasswordRequest {
    actionToken: string;
    newPassword: string;
}

export const resetPassword = async (email: string) => {
    return await api.put(`/credenciais`, { username: email });
}

export const newPasswordKeyCloak = async (payload: INewPasswordRequest) => {
    return await api.put(`/reset-password`, payload);
}
