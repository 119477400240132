import 'bootstrap/dist/css/bootstrap.min.css';
import { toast, ToastContainer } from "react-toastify";
import { RoutesApp } from "./Routes/Routes";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '72a00203-1471-4049-8d10-0e6af6225b9d',
  clientToken: 'pub848be313d05b035b835c71303dc50521',
  site: 'datadoghq.com',
  service: 'portal-agendamentos-entregas',
  env: 'prd',
  sessionSampleRate: 10,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  enablePrivacyForActionName: true,
});

function App() {
  return (
    <>
      <ToastContainer style={{ marginTop: '70px' }} pauseOnHover={false} closeOnClick={false} autoClose={4000} position={toast.POSITION.TOP_RIGHT} />
      <RoutesApp />
    </>
  );
}

export default App;
