import moment from "moment"

export const formatDateToBRL = (date: string | undefined): string => {
    if (!date) return ""
    return moment(date).format("DD/MM/YYYY")
}

export const dateIsNotValidAgendamento = (date: string | undefined): boolean => {
    if (!date) return false
    const dateToCompare = moment(date).subtract(2, "days").startOf("day")
    const currentDate = moment().startOf("day")
    return dateToCompare.isSameOrBefore(currentDate)
}

export const dateIsNotValidPreAgendamento = (date: string | undefined): boolean => {
    if (!date) return false
    const dateToCompare = moment(date).subtract(12, "hours").startOf("day")
    const currentDate = moment().startOf("day")
    return dateToCompare.isSameOrBefore(currentDate)
}