import jwt_decode from "jwt-decode";
import { CurrentUser } from "../types/CurrentUser";

export const getCurrentUserValues: () => CurrentUser = () => {
    const token = localStorage.getItem("authToken");
    if (token) {
        const decoded: CurrentUser = jwt_decode(token);
        return { 
            ...decoded
         } as CurrentUser;
    } else return {} as CurrentUser;
}

export const verifyForHidden = () => {
  const user = getCurrentUserValues()
  if (user?.email?.includes("@grupopanvel")) {
    localStorage.setItem("admin", "true")
    return true;
  }
  localStorage.removeItem("admin")
  return false
}