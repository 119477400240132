import styled from "styled-components";
import { Container } from "@mui/material";

export const StyledContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
`;

export const StyledContainerForResponsive = styled(Container)`
padding-left: 100px !important;
margin-bottom: 10px;

&.margin-header {
    margin-top: 100px;
}
@media (max-width: 1671px) {
    margin: 100px auto 0 auto;
}

@media (max-width: 768px) {
    margin: 0;
    padding: 8px !important;

    &.margin-header {
        margin: 100px auto 0 auto;
    }
}

@media print {
    margin: -10px 0 0 0 !important;
    padding: 0 20px;
    width: 100%;
}
`;