
export enum CredentialsStoreTypes {
  SEND_NEW_PASSWORD = "@CREDENTIALS/SEND_NEW_PASSWORD",
  RESET_PASSWORD = "@CREDENTIALS/RESET_PASSWORD",
  LOADING_SUCCESS = "@CREDENTIALS/LOADING_SUCCESS",
  LOADING_FAILURE = "@CREDENTIALS/LOADING_FAILURE",
}

export interface CredentialsState {
  readonly loading: boolean;
}