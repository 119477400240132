import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Panvel from "../../assets/img/logo-panvel.svg";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { CredentialsStoreTypes } from "../../store/ducks/credentials/types";
import { FormFieldTypesEnum } from "../Forms/render";
import { StyledForm } from "../Forms/styles";
import { PasswordType } from "../Forms/types";
import { StyledButtonSubmit } from "../UI/buttons";
import { ChangePassword } from "./schema";
import { StyledContainerBackground, StyledContainerLogin } from "./styles";
import { toast } from "react-toastify";

interface IFormNewPassword {
    password: string;
    passwordConfirm: string;
}

export const FormNewPassword = (

) => {
    const [tokenString, setTokenString] = useState<string>('');
    const auth = useContext(AuthContext);
    const { loading } = auth;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const paramKey = new URLSearchParams(location?.search).get('key');

    useEffect(() => {
        setTokenString(paramKey ?? '');
    }, [paramKey])

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm<IFormNewPassword>({ resolver: yupResolver(ChangePassword) });
    const [values, setValues] = useState({
        showPassword: false,
        showPasswordConfirm: false,
    });

    const INPUT_PASSWORD: PasswordType[] = [
        {
            label: <Typography className="text-dark" variant="h6">Nova Senha</Typography>,
            type: 'password',
            name: 'password',
            register,
            errors,
            placeholder: '********',
            onClickShowPassword: () => setValues({
                ...values,
                showPassword: !values.showPassword
            }),
            showPassword: values.showPassword,
            value: watch('password') || '',
        },
        {
            label: <Typography className="text-dark" variant="h6">Confirmar Nova Senha</Typography>,
            type: 'password',
            name: 'passwordConfirm',
            register,
            errors,
            placeholder: '********',
            onClickShowPassword: () => setValues({
                ...values,
                showPasswordConfirm: !values.showPasswordConfirm
            }),
            showPassword: values.showPasswordConfirm,
            value: watch('passwordConfirm') || '',
        },
    ];

    const onEnterKeyPressed = (e: any) => {
        if (e.key === 'Enter') {
            handleSubmit(handlePassword)()
        }
    };

    const handlePassword = ((data: any) => {
        const { password } = data;

        dispatch({
            type: CredentialsStoreTypes.SEND_NEW_PASSWORD,
            payload: {
                newPassword: password,
                actionToken: tokenString
            },
            onSuccess: (res: any) => {
                toast.success('Senha alterada com sucesso!')
                navigate('/')
            },
            onError: (err: any) => {}
        })

    });

    return (
        <StyledContainerBackground>
            <img height={80} className="img-logo-login" src={Panvel} alt="Logo" />
            <StyledContainerLogin className="flex-col">
                <StyledForm onKeyDown={(event: any) => onEnterKeyPressed(event)} id="form-new-password" className="form-login pb-1" >
                    {FormFieldTypesEnum.INPUT_PASSWORD(INPUT_PASSWORD)}
                </StyledForm>
                <StyledButtonSubmit disabled={loading} onClick={handleSubmit(handlePassword)} className="button-submit w-100 mt-3"
                    type="submit"
                >
                    Enviar
                </StyledButtonSubmit>
            </StyledContainerLogin>
        </StyledContainerBackground>
    )
}