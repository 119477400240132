import styled from 'styled-components';

export const StyledAdvancedSearch = styled.div`
  border-radius: 5px; 
  background-color: #f5f5f5;
  padding: 12px 6px 6px;
  margin: 6px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;