import { GenericLineType } from "../types/GenericLine";

export function toDateFormat(dateTimeString: string): string {
  // Primeiro, dividimos a data completa em duas partes:
  //const [date, time ] = dateTimeString.split("T");
  const [date] = dateTimeString.split("T");

  // Dividimos a data em dia, mês e ano:
  const [DD, MM, YYYY] = date.split("-");

  // Dividimos o tempo em hora e minutos:
  //const [HH, mm, ss] = time.split(":");

  //Retornamos a data
  //${HH}:${mm}:${ss}
  return `${YYYY}/${MM}/${DD}`;
}

export function validateIfIsDate(date: any): boolean {
  return Object.prototype.toString.call(date) === '[object Date]'
}

export const convertDateToLocal = (date: any) => {
  const dateCheck = Date.parse(date);
  const localeString = new Date(dateCheck).toLocaleString("pt-br").split(" ")[0];
  return localeString;
}

function getRandomUniqueNumber(existingKeys: Set<number>, maxRandomValue: number): number {
  let randomNumber: number;
  do {
    randomNumber = Math.floor(Math.random() * maxRandomValue);
  } while (existingKeys.has(randomNumber));
  return randomNumber;
}

function hasLineKey<T extends GenericLineType>(item: T): item is T & Required<GenericLineType> {
  return 'lineKey' in item && item.lineKey !== undefined;
}

export function addLineKeyToArrayItems<T extends GenericLineType>(list: T[]): T[] {
  const existingKeys = new Set<number>();
  const maxRandomValue = 1000000;

  return list?.map((item) => {
    if (hasLineKey(item)) {
      existingKeys.add(item.lineKey);
      return item;
    } else {
      const lineKey = getRandomUniqueNumber(existingKeys, maxRandomValue);
      existingKeys.add(lineKey);
      return { ...item as Object, lineKey } as unknown as T & Required<GenericLineType>;
    }
  });
}

export const isFunction = (method: any) => typeof method === "function";