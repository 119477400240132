import { ValidationKeysRequest } from "../types/Agendamentos/agendamento.d";
import { getCurrentUserValues } from "../utils/auth";
import api from "./apiTs";

const getListOfSchedules = async () => {
    const { email } = getCurrentUserValues();
    const emailString = email ? `?email=${email}` : '';
    return await api.get(`/agendamento/ticket${emailString}`)
}

const getRelatorios = async () => {
    const { email } = getCurrentUserValues();
    const emailString = email ? `?email=${email}` : '';
    return await api.get(`/agendamento/relatorio${emailString}`)
}

const deleteTicketByCode = async (ticket: number) => {
    return await api.delete(`/agendamento/${ticket}`)
}

const getTicketByTicketNumber = async (ticket: number) => {
    return await api.get(`/agendamento/${ticket}`)
}

const verifyKeys = async (request: any) => {
    const payload: ValidationKeysRequest = request?.payload;
    return await api.get(`/notas/cd/${Number(payload?.cd)}/chaves${payload?.chaves}${payload?.ticket ? `&ticket=${payload?.ticket}` : ''}`)
}

const postAgendamento = async (action: any) => {
    return await api.post("/agendamento", action.payload)
}

const putAgendamento = async (action: any) => {
    return await api.put("/agendamento", action.payload)
}

const getTransportDetails = async (action: any) => {
    return await api.get(`/agendamento/transportadora?cnpj=${String(action?.payload?.cnpj)}`);
}

const getDatasDeAgendamentoDisponiveis = async (request: any) => {
    const { payload } = request;
    const { cd, quantidadeSku, volume, ticket } = payload;
    const pathString = `/agendamento/datas?${cd ? `cd=${cd}` : ''}${quantidadeSku ? `&sku=${quantidadeSku}` : ''}${volume ? `&volume=${volume}` : ''}${ticket ? `&ticket=${ticket}` : ''}`;
    return await api.get(pathString);
}

export {
    getTicketByTicketNumber,
    getRelatorios,
    getDatasDeAgendamentoDisponiveis,
    getListOfSchedules,
    getTransportDetails,
    postAgendamento,
    putAgendamento,
    verifyKeys,
    deleteTicketByCode
};

