import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import Panvel from "../../assets/img/logo-panvel.svg";
import { StyledContainerBackground, StyledContainerLogin } from "../FormLogin/styles";
import { FormFieldTypesEnum } from "../Forms/render";
import { StyledForm } from "../Forms/styles";
import { InputType } from "../Forms/types";
import { StyledButtonSubmit } from "../UI/buttons";
import { useEffect, useState } from "react";

const schema = yup.object({ email: yup.string().required("Campo obrigatório").email("Email inválido") });

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tokenValue = params.get('key');
    setToken(tokenValue);
  }, [params]);



  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleSuccessAlert = () => {
    toast.success("Instruções enviadas por e-mail. Por favor, siga para redefinir sua senha.");
    navigate("/");
  };

  const handleFailureAlert = () => {
    toast.error("Falha na solicitaçao de senha");
    navigate("/");
  };

  function handleForgot(data: any) {
   dispatch({
    type: "@CREDENTIALS/RESET_PASSWORD",
    payload: {
      email: data?.email
    },
    onSuccess: handleSuccessAlert,
    onError: handleFailureAlert
   })
  }

  const INPUTS: InputType[] = [
    {
      label: <Typography className="text-dark" variant="h6">Email</Typography>,
      type: 'text',
      name: 'email',
      register,
      errors,
      placeholder: 'email@email.com'
    },
  ]

  return (
    <>
      <ToastContainer autoClose={3000} position={toast.POSITION.TOP_RIGHT} />
      <StyledContainerBackground>
        <img height={80} className="img-logo-login" src={Panvel} alt="Logo" />
        <StyledContainerLogin>
          <StyledForm className="form-login pb-2" onSubmit={handleSubmit(handleForgot)}>
            {FormFieldTypesEnum.INPUT(INPUTS)}
            <StyledButtonSubmit className="button-submit w-100 mt-3" type="submit" id="botaoEntrar">
              Enviar
            </StyledButtonSubmit>
          </StyledForm>
        </StyledContainerLogin>
      </StyledContainerBackground>
    </>
  );
};

export default ForgotPassword;
