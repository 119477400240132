import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { filterToOnlyNumbers } from '../../../utils/strings';

const enumMessages = {
  QUANTIDADE_VEICULOS: 'Quantidade de veículos é obrigatória ao editar um pre-agendamento.',
  CHAVES_DE_ACESSO: 'Chaves de acesso são obrigatórias ao editar um pre-agendamento.',
  TIPO_FRETE: 'Tipo de frete é obrigatório ao editar um pre-agendamento.',
}

const isValidFieldSelected = (value: string | undefined) => {
  if (value && value !== ('' || 'Selecione')) {
    return true;
  }
  return false;
}

export const FormDadosEChavesDeAcessoPreAgendamento = Yup.object().shape({
  ticket: Yup.string(),
  cnpj: Yup.string()
    .test('valid-cnpj', 'CNPJ inválido', function (value) {
      const normalizedCnpj = value && filterToOnlyNumbers(value);
      if (!normalizedCnpj || normalizedCnpj.length !== 14) return false;
      return true;
    })
    .required(),
  email: Yup.string().email().required(),
  motorista: Yup.string(),
  telefone: Yup.string().required(),
  tipoFrete: Yup.string().test('valid-tipoFrete', 'Selecione um Tipo de Frete', function (value) {
    return isValidFieldSelected(value)
  }).required(),
  nomeTransportadora: Yup.string().required(),
  quantidadeDeVeiculos: Yup.number().required().min(1).default(1).typeError('Quantidade de veículos inválida'),
  cdDestino: Yup.string().test('valid-cd-destino', 'Selecione um CD válido.', function (value) {
    return isValidFieldSelected(value)
  }).required(),
  chavesDeAcesso: Yup.string(),
  chavesXml: Yup.array().of(Yup.mixed()),
});

export const PreAgendamentoNotesSchema = Yup.object().shape({
  volumeAnterior: Yup.number().required().min(1).default(0).typeError('Quantidade de número de caixas inválida'),
  skuAnterior: Yup.number().required().min(1).default(0).typeError('Quantidade de sku inválida'),
  chavesAcesso: Yup.string().default('').required('Necessário adicionar chaves ao pré-agendamento antes de enviar.'),
  volume: Yup.number()
    .required("Número de caixas deve ser preenchido")
    .min(1, "Número de caixas deve ser maior que zero")
    .default(0)
    .typeError('Quantidade de número de caixas inválida')
    .test(
      'valid-volume',
      'Número de caixas deve ser igual ou menor que o pré-agendado',
      function (value) {
        const { volumeAnterior } = this.parent;
        return value <= volumeAnterior;
      }
    ),
  sku: Yup.number()
    .required("SKU deve ser preenchido")
    .min(1, "Quantidade de SKU inválida")
    .default(0)
    .typeError('Quantidade de SKU inválida')
    .test(
      'valid-sku',
      'SKU deve ser igual ou menor que o pré-agendado',
      function (value) {
        const { skuAnterior } = this.parent;
        return value <= skuAnterior;
      }
    ),
  justificativa: Yup.string().default('')
    .test(
      'valid-justificativa',
      'Justificativa necessária ao informar número de caixas/sku menor que o pré-agendado.',
      function (value, context) {
        const { volume, sku, volumeAnterior, skuAnterior } = context.parent;
        const isVolumeInvalid = volume < volumeAnterior;
        const isSkuInvalid = sku < skuAnterior;
        if ((isVolumeInvalid || isSkuInvalid) && !value) {
          return false
        } else if ((isVolumeInvalid || isSkuInvalid) && value?.length < 10) {
          return this.createError({ message: 'Justificativa deve ter no mínimo 10 caracteres.' });
        }
        return true;
      } as Yup.TestFunction<string, AnyObject>)
    .max(200, 'Máximo de 200 caracteres'),
  data: Yup.string()
    .required("Necessário informar a data de entrega")
    .test('valid-data', 'Data inválida', (value) => {
      return !!(value && value !== '' && value !== 'Selecione');
    }),
  dataDesejada: Yup.string().required("Necessário informar a data que você gostaria que tivesse disponíbilidade para o agendamento.")
    .test('valid-data', 'Data inválida', function (value) {
      return !!(value && value !== '' && value !== 'Selecione');
    }),
});