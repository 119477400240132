import styled from "styled-components";
import { MenuItem, Select, SelectProps, TextField } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import { azulForte, azulFraco } from "../UI/variaveis";

export const StyledForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 100%;
  min-width: 200px;

  &.form-login {
    grid-template-columns: 1fr;
  }

  .submit-button {
    background-color: ${azulFraco};
    grid-column: span 2;
    min-width: 20%;
    max-width: max-content;

    &:hover {
      background-color: ${azulForte};
    }
  }

  #endDate,
  #startDate {
    width: 100%;
    display: flex;
  }

  & > div {
    width: 100%;
  }

  & .input-upload-file {
    display: none;
  }

  & .input-upload {
    padding: 11px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  & .full-width {
    grid-column: 1 / -1;
  }

  & .grey-bg {
    background: linear-gradient(#f2f2f2, #d9d9d9);
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    padding: 60px;

    & > div {
      width: 100%;
    }
  }

  & .form-footer {
    grid-column: span 2;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
  }

  &.volumes-skus {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    background: linear-gradient(#f2f2f2, #d9d9d9);
    border-radius: 15px;
    flex-wrap: wrap;
    min-height: 200px;
    height: auto;
    padding: 40px 30px;

    & > div {
      width: 46% !important;
    }
  }
`;

export const StyledDiv = styled.div`
  flex: 1 0 21%;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 56px;

  & input {
    width: 100%;
  }

  & div.react-datepicker__day--selected {
    background-color: ${azulForte};
  }
`;

export const StyledFormFooter = styled.div`
  grid-column: span 2;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
`;

export const StyledDatePicker = styled(ReactDatePicker)`
height: 56px;
width: 48%;
border-radius: 4px;
  cursor: pointer;
  line-height: 1.5rem;
  padding: 0 0 0 15px;
  border: 0.5px solid #adb5bd;
  color: #202224c1;
  
  &:hover {
    border: 0.5px solid #212529;
  }
  
  ${props => props?.disabled && `
    background-color: #e9ecef;
    cursor: not-allowed;
  `}
`;

export const StyledTextField = styled(TextField)`
  grid-column: span 2;
`;

export const StyledLink = styled.div`
  color: ${azulFraco};
  text-decoration: none;

  position: absolute;
  right: 85px;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);


  & :hover {
    color: ${azulForte};
`;

export const StyledMenuItem = styled(MenuItem)``;

export const StyledSelect = styled(Select)`` as React.ComponentType<SelectProps>;