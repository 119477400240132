import { call, put } from "redux-saga/effects";
import { newPasswordKeyCloak, resetPassword } from "../../../service/credentials.service";

import { onErrorCatch } from "../service";
import { CredentialsStoreTypes } from "./types";

export function* resetPasswordSaga(action: any): any {
  try {
    const res = yield call(resetPassword, action?.payload?.email);
    yield put({ type: CredentialsStoreTypes.LOADING_SUCCESS })
    action?.onSuccess(res?.data);
  } catch (error: any) {
    yield put({ type: CredentialsStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
  }
}

export function* sendNewPasswordSaga(action: any): any {
  try {
    const res = yield call(newPasswordKeyCloak, action?.payload);
    if (res?.status === 202) {
      yield put({ type: CredentialsStoreTypes.LOADING_SUCCESS })
      action?.onSuccess(res?.data);
    }
  } catch (error: any) {
    yield put({ type: CredentialsStoreTypes.LOADING_FAILURE })
    onErrorCatch(error, action)
  }
}