import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { RelatorioState } from "../../../../store/ducks/relatorios/types";
import { IRelatorio } from "../../../../types/Agendamentos/relatorio";
import { StyledDownloadIcon } from "../../../UI/Icons/StyledDownloadIcon";
import { StyledButtonBlue } from "../../../UI/buttons";
import { StyledContainerForResponsive } from "../../../UI/containers";
import CustomPagination from '../Pagination';
import { linesWithPagination } from '../service';
import {
    StyledBootstrapTable,
    StyledContainerForSelector
} from "../styles";
import { exportToXLSX } from "./service";
import { formatDateToBRL } from "../../../../utils/date";

function TableRelatorios() {
    const dispatch = useDispatch();
    const [maxItemsPerPage, setMaxItemsPerPage] = useState(15);
    const [currentPage, setCurrentPage] = useState(0);
    const valorMinimoPaginacao = 15;
    const { loading, relatorios } = useSelector<ApplicationState, RelatorioState>((state) => state.relatorios);
    const [listaRelatorios, setListaRelatorios] = useState<IRelatorio[]>([]);
    const [totalPaginas, setTotalPaginas] = useState<number>(0);

    useEffect(() => {
        if (!relatorios?.length && !loading) {
            dispatch({
                type: "@relatorios/GET_RELATORIOS",
                onSuccess: () => { },
                onFailure: () => { },
            })
        }
    }, []);

    useEffect(() => {
        if (relatorios?.length && !listaRelatorios?.length || relatorios?.length && JSON.stringify(relatorios) !== JSON.stringify(listaRelatorios)) {
            setListaRelatorios(linesWithPagination(relatorios, currentPage, maxItemsPerPage));
        }
    }, [relatorios])

    useEffect(() => {
        if (relatorios?.length) {
            setTotalPaginas(Math.ceil(relatorios?.length / maxItemsPerPage));
        }
    }, [relatorios, maxItemsPerPage])

    useEffect(() => {
        setCurrentPage(0);
    }, [maxItemsPerPage]);

    useEffect(() => {
        setListaRelatorios(linesWithPagination(relatorios, currentPage, maxItemsPerPage));
    }, [currentPage]);

    useEffect(() => {
        setListaRelatorios(linesWithPagination(relatorios, currentPage, maxItemsPerPage));
    }, [relatorios])

    const changePageValue = (numeroDaPagina: number) => {
        if (numeroDaPagina === currentPage) return
        setCurrentPage(numeroDaPagina);
    }

    const renderPagination = () => {
        if (relatorios?.length > valorMinimoPaginacao) {
            return (<CustomPagination
                currentPage={currentPage}
                changePageValue={changePageValue}
                totalPaginas={totalPaginas}
                key="pagination-relatorios"
            />)
        }
    }

    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    <th>Ticket</th>
                    <th>Número de caixas</th>
                    <th>Data do Agendamento</th>
                    <th>Situação</th>
                </tr>
            </thead>
        )
    }

    const renderRelatorioRows = (relatorios: IRelatorio[]) => {
        return (
            <tbody className="table-hover">
                {relatorios?.map((item: IRelatorio) => {
                    return (
                        <tr id={`table-line-relatorios-${item?.ticket}`} key={`table-line-keys-${item?.ticket}`}>
                            <td>{item?.ticket}</td>
                            <td>{item?.volumes}</td>
                            <td>{formatDateToBRL(item?.dataAgendamento)}</td>
                            <td>{item?.statusAgendamento}</td>
                        </tr>
                    )
                })}
            </tbody>
        )
    }

    const renderFooter = (listaRelatorios: IRelatorio[]) => {
        return (
            <tfoot>
                <tr>
                    <td colSpan={6}>
                        {!listaRelatorios?.length && ("Nenhum registro encontrado")}
                        {(listaRelatorios?.length && relatorios.length > valorMinimoPaginacao)
                            ? <StyledContainerForSelector>
                                <label>Máximo de linhas por pagina:</label>
                                <select id="max-per-page-select" title="Máximo de linhas por pagina" value={maxItemsPerPage} onChange={(e) => setMaxItemsPerPage(Number(e.target.value))}>
                                    <option value="15">15</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                </select>
                            </StyledContainerForSelector> : <></>}
                    </td>
                </tr>
            </tfoot>
        )
    }

    return (
        <StyledContainerForResponsive maxWidth="xl" className="mb-4">
            <div className="d-flex justify-content-end pb-2">
                <StyledButtonBlue
                    title={!listaRelatorios?.length ? "Necessário ter registros para exportar" : "Exportar Tabela"}
                    hidden={!relatorios?.length} onClick={() => exportToXLSX(relatorios)}
                    className="btn btn-primary"
                >
                    {`Exportar Tabela`}<StyledDownloadIcon />
                </StyledButtonBlue>
            </div>
            {<StyledBootstrapTable style={{ borderRadius: '5px' }} id='keys-table' striped hover>
                {renderHeader()}
                {renderRelatorioRows(listaRelatorios)}
                {renderFooter(listaRelatorios)}
            </StyledBootstrapTable>}
            {renderPagination()}
        </StyledContainerForResponsive>
    );
}

export default TableRelatorios;