import { Pagination } from "react-bootstrap";

export const addNovoEspaco = (dispatch: () => void, navigate: () => void, clearCadastro: () => void) => {
    clearCadastro();
    dispatch();
    navigate();
}

export const linesWithPagination = (list: any[], currentPage: number, maxItemsPerPage: number) => {
    const start = currentPage * maxItemsPerPage;
    const end = start + maxItemsPerPage;
    return list?.length ? list?.slice(start, end) : [];
}

export const determinedStyleCheckByDates = (item: { startDate: Date, endDate: Date | null }) => {
    const currentDate = new Date();
    const startDate = new Date(item?.startDate);
    let endDate: any;

    if (item?.endDate) endDate = new Date(item?.endDate);

    const isEndDateValid = endDate ? currentDate < endDate : false;
    const isStartDateValid = startDate <= currentDate
        && currentDate > startDate
        && (!endDate || currentDate < endDate);

    if (isStartDateValid && (isEndDateValid || !endDate)) {
        return 'valid';
    } else if (!isStartDateValid && startDate < currentDate) {
        return 'invalid';
    } else {
        return '';
    }
};

export const renderPageNumbers = (totalPaginas: number, currentPage: number, changePageValue: (value: number) => void) => {
    const pageNumbers = [];
    const maxVisiblePages = 9;

    if (totalPaginas <= maxVisiblePages) {
        for (let i = 0; i < totalPaginas; i++) {
            pageNumbers.push(
                <Pagination.Item id="actual" key={i} active={i === currentPage} onClick={() => changePageValue(i)}>
                    {i + 1}
                </Pagination.Item>
            );
        }
    } else {
        let startPage = Math.max(0, currentPage - 4);
        let endPage = Math.min(totalPaginas - 1, currentPage + 4);

        if (endPage - startPage < maxVisiblePages - 1) {
            if (startPage === 0) {
                endPage = maxVisiblePages - 1;
            } else if (endPage === totalPaginas - 1) {
                startPage = totalPaginas - maxVisiblePages;
            }
        }

        if (startPage > 0) {
            pageNumbers.push(<Pagination.Ellipsis key="ellipsis-start" />);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item id="actual" key={`pagination-${i}`} active={i === currentPage} onClick={() => changePageValue(i)}>
                    {i + 1}
                </Pagination.Item>
            );
        }

        if (endPage < totalPaginas - 1) {
            pageNumbers.push(<Pagination.Ellipsis key="ellipsis-end" />);
        }
    }

    return pageNumbers;
};