import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { mapResponseAgendamentoToTicket } from "../../components/_Agendamentos/mapper";
import { INotaFiscal, IResponseAgendamento, ITicket, ITicketReduzido } from "../../types/Agendamentos/agendamento.d";
import { AgendamentoContext } from "./AgendamentoContext";

export const AgendamentoProvider: React.FC = (props: PropsWithChildren<any>) => {
  const [listaDeChaves, setListaDeChaves] = useState<string[]>([]);
  const [listaDeNotasFiscais, setListaDeNotasFiscais] = useState<INotaFiscal[]>([]);
  const [listaDeRelatorios, setListaDeRelatorios] = useState<ITicketReduzido[]>([]);
  const [ticket, setTicket] = useState({} as ITicket);

  const changeTicket = (ticket: IResponseAgendamento): void => setTicket(mapResponseAgendamentoToTicket(ticket));

  const value = useMemo(() => ({
    listaDeChaves,
    setListaDeChaves,
    listaDeNotasFiscais,
    setListaDeNotasFiscais,
    ticket,
    setTicket: changeTicket,
    listaDeRelatorios,
    setListaDeRelatorios,
  }), [listaDeNotasFiscais, ticket, listaDeRelatorios, listaDeChaves]);

  return (
    <AgendamentoContext.Provider value={value}>
      {props?.children}
    </AgendamentoContext.Provider>
  );
};
