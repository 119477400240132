import { INotaFiscal } from "../../../types/Agendamentos/agendamento.d";

export const formatStringToArray = (keys: string) => {
    if (!keys || !keys.length) return [];
    return keys?.replaceAll(" ", "\n")?.replaceAll(/\n/g, "\n")?.split("\n");
};

export const mergeNotaFiscais = (existingList: INotaFiscal[], newList: INotaFiscal[]): INotaFiscal[] => {
  const mergedList: INotaFiscal[] = [];

  const existingMap = new Map<string, INotaFiscal>();
  existingList.forEach((nota) => {
    existingMap.set(nota.chave, nota);
  });

  newList.forEach((nota) => {
    existingMap.set(nota.chave, nota);
  });

  existingMap.forEach((nota) => {
    mergedList.push(nota);
  });

  return mergedList;
}

export const getTotalSkusDasNotas = (notas: INotaFiscal[]): number => {
    return notas?.map((nota: INotaFiscal) => nota?.quantidadeSku)?.reduce((a: number, b: number) => a + b, 0) || 0;
}