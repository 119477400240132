import axios, { AxiosRequestConfig } from "axios";
import url from "./url";

const api = axios.create({
  baseURL: `${url()}/`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
  timeout: 30000,
  validateStatus: function (status) {
    if (status === 403 || status === 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("admin");
      localStorage.removeItem("cnpj");
      window.location.replace("/");
    }

    return status >= 200 && status < 300;
  },
});

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    config.headers
      ? (config.headers["Authorization"] = `Bearer ${token}`)
      : null;
  }
  return config;
});

export default api;
