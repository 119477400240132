import { PropsWithChildren, useMemo, useState } from "react";
import { PreAgendamentoRowResponse } from "../../types/PreAgendamentos/preAgendamento";
import { PreAgendamentoContext } from "./PreAgendamentoContext";

export const PreAgendamentoProvider: React.FC = (props: PropsWithChildren<any>) => {
  const [preAgendamentoContext, setPreAgendamentoContext] = useState<PreAgendamentoRowResponse>({} as PreAgendamentoRowResponse);

  const value = useMemo(() => ({
    preAgendamentoContext,
    setPreAgendamentoContext
  }), [preAgendamentoContext, setPreAgendamentoContext]);

  return (
    <PreAgendamentoContext.Provider value={value}>
      {props?.children}
    </PreAgendamentoContext.Provider>
  );
};
