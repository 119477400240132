import { Reducer } from "redux";
import { mapResponseAgendamentoToTicket } from "../../../components/_Agendamentos/mapper";
import { ITicket, ITicketReduzido } from "../../../types/Agendamentos/agendamento.d";
import { AgendamentosState, AgendamentosStoreTypes } from "./types";

const INITIAL_STATE: AgendamentosState = {
    agendamentos: [] as ITicketReduzido[],
    ticketsComNotas: [] as unknown as ITicket[],
    loading: false,
    loadingData: false,
};

const reducer: Reducer<AgendamentosState> = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case AgendamentosStoreTypes.GET_AGENDAMENTOS:
            return { ...state, loading: true };

        case AgendamentosStoreTypes.GET_DATAS_DISPONIVEIS:
            return {
                ...state,
                loadingData: true,
            };

        case AgendamentosStoreTypes.GET_VERIFY_KEYS:
            return { ...state, loading: true };

        case AgendamentosStoreTypes.GET_TICKET_BY_CODE:
            return { ...state, loading: true };

        case AgendamentosStoreTypes.GET_TRANSPORT_DETAILS:
            return { ...state, loading: true };

        case AgendamentosStoreTypes.POST_AGENDAMENTO:
            return { ...state, loading: true };

        case AgendamentosStoreTypes.DELETE_TICKET_BY_CODE:
            return { ...state, loading: true };

        case AgendamentosStoreTypes.LOAD_AGENDAMENTOS:
            return {
                ...state,
                agendamentos: [
                    ...action.payload
                ],
                ticketsComNotas: [],
                loading: false
            };

        case AgendamentosStoreTypes.LOAD_TICKET_COM_NOTA: {
            let exists = state.ticketsComNotas?.find((ticket) => ticket?.ticket === action.payload?.ticket);
            if (!exists) {
                return {
                    ...state,
                    ticketsComNotas: [
                        ...state.ticketsComNotas,
                        mapResponseAgendamentoToTicket(action.payload)
                    ],
                    loading: false
                }
            } else {
                return {
                    ...state,
                    listaComNotas: [
                        state.ticketsComNotas?.filter((ticket: ITicket) => ticket?.ticket !== action.payload?.ticket),
                        mapResponseAgendamentoToTicket(action.payload)
                    ],
                    loading: false
                }
            }
        }

        case AgendamentosStoreTypes.LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingData: false
            };

        case AgendamentosStoreTypes.LOADING_FAILURE:
            return {
                ...state,
                agendamentos: [],
                loading: false,
                loadingData: false
            };


        default:
            return state;
    }
};

export default reducer;
