import { Backdrop, CircularProgress } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import jwt_decode from "jwt-decode";
import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from '../components/Header';
import StoreContext from '../components/Store/Context';
import { StyledLoading } from "../components/UI/loading";
import UserRegister from "../components/UserRegister/UserRegister";
import TableAgendamentos from '../components/_Agendamentos/Tables/TableAgendamentos';
import TicketPage from '../components/_Agendamentos/Ticket';
import Forgot from "../pages/Forgot";
import Login from "../pages/Login";
import { Relatorios } from '../pages/Relatorios';
import { ApplicationState } from '../store';
import { AgendamentosState } from '../store/ducks/agendamentos/types';
import { RelatorioState } from '../store/ducks/relatorios/types';
import buildRouresByRole from './MenuRoutes';
import { FormNewPassword } from '../components/FormNewPassword';
import TablePreAgendamentos from '../components/_PreAgendamento/TablePreAgendamentos';
import PreAgendamentoNotasForm from '../components/_PreAgendamento/PreAgendamentoNotasForm';
import { PreAgendamentosState } from '../store/ducks/preAgendamento/types';

const PreAgendamentoForm = lazy(() => import("../components/_PreAgendamento/PreAgendamentoForm"));
const AgendamentoForm = lazy(() => import("../components/_Agendamentos/AgendamentoForm"));

export const RoutesApp = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { loading: loadingAgendamentos, loadingData } = useSelector<ApplicationState, AgendamentosState>((state) => state?.agendamentos);
    const { loading: loadingRelatorios } = useSelector<ApplicationState, RelatorioState>((state) => state?.relatorios);
    const { loading: loadingPreAgendamento } = useSelector<ApplicationState, PreAgendamentosState>((state) => state?.preAgendamentos);

    const { user } = useContext(StoreContext);
    const [currentUser, setCurrentUser] = useState<any>();

    useEffect(() => {
        if (user) setCurrentUser(user);
        else setCurrentUser('');
    }, [user]);

    const getLocation = () => {
        return window.location.pathname;
    }

    const getToken: any = () => {
        return localStorage.getItem("authToken");
    }

    const isValidToken = () => {
        let tokenDecoded: any = getToken() && jwt_decode(getToken());
        return tokenDecoded?.email && tokenDecoded?.cnpj
    }

    const existValidToken = () => {
        return !!getToken() && isValidToken()
    }

    const isCurrentLocation = (path: string = "") => {
        return getLocation() == path;
    }

    const privateRoutes = () => {
        const menuCurrentLocation = buildRouresByRole(currentUser?.email).find((menu: any) => isCurrentLocation(menu.path))
        if (menuCurrentLocation?.isPrivate) {
            if (isCurrentLocation(menuCurrentLocation.path) && !existValidToken()) {
                window.location?.replace("/");
            }
        }
    }

    function suspendComponent(Component: JSX.Element) {
        return (
            <Suspense fallback={<StyledLoading defaultChecked />} >
                {Component}
            </Suspense>
        );
    }

    return (
        <BrowserRouter>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={
                    loadingAgendamentos ||
                    loadingRelatorios ||
                    loadingData ||
                    loadingPreAgendamento
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Routes>
                {privateRoutes()}
                <Route path="/" element={<Login />} />
                <Route path="/actuator/health" />
                <Route path="/forgot" element={<Forgot />} />
                <Route
                    path="/cadastro"
                    element={
                        <>
                            {suspendComponent(<Header setIsOpen={setIsOpen} />)}
                            <UserRegister />
                        </>}
                />
                <Route
                    path="/agendamento"
                    element={
                        <>
                            {suspendComponent(<Header setIsOpen={setIsOpen} />)}
                            {suspendComponent(<AgendamentoForm />)}
                        </>
                    }
                />
                <Route
                    path="/ticket"
                    element={
                        <>
                            {suspendComponent(<Header setIsOpen={setIsOpen} />)}
                            <TicketPage />
                        </>
                    }
                />
                <Route path='/agendamentos' element={
                    <>
                        {suspendComponent(<Header setIsOpen={setIsOpen} />)}
                        {suspendComponent(<TableAgendamentos />)}
                    </>
                } />
                <Route path='/agendamento/:id' element={
                    <>
                        {suspendComponent(<Header setIsOpen={setIsOpen} />)}
                        {suspendComponent(<AgendamentoForm />)}
                    </>
                } />
                <Route path='/relatorios' element={
                    <>
                        {suspendComponent(<Header setIsOpen={setIsOpen} />)}
                        {suspendComponent(<Relatorios />)}
                    </>
                } />
                <Route path='/realms/dock-scheduling-service/login-actions/action-token' element={
                    <>
                        {suspendComponent(<FormNewPassword />)}
                    </>
                } />
                <Route path='/pre-agendamento' element={
                    <>
                        {suspendComponent(<Header setIsOpen={setIsOpen} />)}
                        {suspendComponent(<PreAgendamentoForm />)}
                    </>
                } />
                <Route path='/pre-agendamentos' element={
                    <>
                        {suspendComponent(<Header setIsOpen={setIsOpen} />)}
                        {suspendComponent(<TablePreAgendamentos />)}
                    </>
                } />
                <Route path='/pre-agendamento-notas' element={
                    <>
                        {suspendComponent(<Header setIsOpen={setIsOpen} />)}
                        {suspendComponent(<PreAgendamentoNotasForm />)}
                    </>
                } />
            </Routes>
        </BrowserRouter >
    )
}