import { Reducer } from "redux";
import { CredentialsState, CredentialsStoreTypes } from "./types";

const INITIAL_STATE = {
    loading: false
};

const reducer: Reducer<CredentialsState> = (state = INITIAL_STATE, action) => {
    switch (action?.type) {
        case CredentialsStoreTypes.LOADING_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }

        case CredentialsStoreTypes.LOADING_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action?.payload
            };
        }

        case CredentialsStoreTypes.SEND_NEW_PASSWORD: {
            return {
                ...state,
                loading: true
            };
        }

        case CredentialsStoreTypes.RESET_PASSWORD: {
            return {
                ...state,
                loading: true
            };
        }

        default: return state;
    }
};

export default reducer;